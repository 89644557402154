@use '@angular/material' as mat;
@use 'animate.css/animate.min.css';
@use '@mdi/font/css/materialdesignicons.css';
@use 'bootstrap/scss/bootstrap';
@use '@fortawesome/fontawesome-free/css/all.min.css';
@use 'variables.scss' as var;

@include mat.core();

$my-primary: mat.m2-define-palette(mat.$m2-blue-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);

$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

@include mat.all-component-themes($my-theme);

@font-face {
  font-family: var.$font-workSans-regular;
  src: url('assets/fonts/WorkSans-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: var.$font-workSans-semibold;
  src: url('assets/fonts/WorkSans-SemiBold.ttf') format('truetype');
  font-display: swap;
  font-weight: 800 !important;
}

::-ms-reveal,
::-ms-clear {
  display: none;
}

.mobile-nav-link-active {
  color: var(--primary) !important;

  svg {
    fill: var(--primary);
  }

  .learner-icons {
    background-color: var(--primary) !important;
  }
}

.admin-dt-more-btn {
  display: unset !important;
  height: 0 !important;
  width: 0 !important;

  &:hover {
    background-color: var.$solitude !important;
    padding: 5px 7px 5px 7px;
    border-radius: 50%;
  }
}

.btn-tags {
  background-color: var.$white;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid var.$lightWhisper;

  .mat-mdc-tab-group {
    .mat-mdc-tab {
      box-sizing: border-box;
      border-radius: 20px;
      background-color: var.$white;
      border: solid 1px var.$veryLightGray;
      opacity: 1;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
      letter-spacing: normal;
      font-size: 12px;
      height: 28px !important;
      color: var.$dimGray;
      margin-right: 8px;
      margin-left: 8px;
      text-transform: none;

      .mdc-tab-indicator {
        display: none;
      }

      .mdc-tab__text-label {
        color: var.$dimGray;
      }
    }

    .mat-mdc-tab-header {
      border: none !important;

      .mat-mdc-tab-label-container {
        border-radius: 28px;

        .mdc-tab__text-label {
          color: var.$black;
        }

        .mdc-tab-indicator {
          .mdc-tab-indicator__content {
            border-color: var.$black;
          }

          .mdc-tab-indicator--active {
            background-color: var.$white !important;
            color: var.$black;
            border: solid 1px var(--primary) !important;
          }
        }
      }
    }
  }

  .monitor-lb-freq {
    .mat-mdc-tab {
      background-color: white !important;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    }
  }
}

.mat-mdc-tab-group {
  .mat-mdc-tab {
    letter-spacing: normal;
  }
}

.admin-dt-more-btn:active {
  background: var.$solitude;
}

.content-select-dropdown {
  .mat-select-search-input {
    margin: 4px;
  }

  .mat-select-search-no-entries-found {
    padding: 10px;
  }
}

.mat-mdc-button {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
  letter-spacing: normal !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
  font-size: 14px !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mdc-text-field--filled {
  .mdc-floating-label {
    font-size: 14px !important;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
    letter-spacing: normal !important;
  }
}

.mat-mdc-button.mat-mdc-button-base {
  height: auto;
  font-size: 13px;
}

.new-review-back-btn {
  position: relative;
  top: 3px;
  font-size: 20px;
  border: 1px solid #eaeaea;
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  padding-top: 2px;
}

.btn-group-toggle > .btn-group > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn input[type='radio'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.autocomplete .dropdown-menu {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 105px;
  overflow-y: scroll;

  .dropdown-item {
    padding: 4px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;

    &.active {
      background-color: var.$veryLightGray !important;
    }
  }
}

.blue-snackbar {
  background: var(--primary) !important;

  .mat-mdc-simple-snack-bar {
    color: var.$white !important;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  }

  .mdc-snackbar__surface {
    background-color: var(--primary) !important;

    .mat-mdc-snack-bar-label {
      color: var.$white;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;

      .mdc-button {
        color: var.$white !important;
      }
    }
  }

  .mdc-button__label {
    border: solid 1px #ffffff75;
    padding: 5px 10px;
    background: var.$white;
    border-radius: 5px;
    color: var(--primary);
    font-weight: 600;
  }
}

.snackbar-info {
  background: linear-gradient(180deg, #85a9f0 0%, #4b8ff4 100%);
  border-radius: 5px;
  .mat-mdc-simple-snack-bar {
    color: var.$white !important;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    padding: 10px;
  }
  .mdc-snackbar__surface {
    background: transparent !important;
    box-shadow: none;
    .mat-mdc-snack-bar-label {
      color: var.$white;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    }
  }
  .mdc-button__label {
    background: var(--primary);
    padding: 5px 10px;
    color: var.$white;
    font-weight: 600;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  }
}

.snackbar-dark {
  background: linear-gradient(180deg, #8e97a9 0%, #394455 100%);
  border-radius: 5px;
  .mat-mdc-simple-snack-bar {
    color: var.$white !important;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    padding: 10px;
  }
  .mdc-snackbar__surface {
    background: transparent !important;
    box-shadow: none;
    .mat-mdc-snack-bar-label {
      color: var.$white;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    }
  }
}

.snackbar-gold {
  background: linear-gradient(
    291.22deg,
    rgba(255, 210, 53, 0.6) -2.54%,
    rgba(255, 237, 172, 0.6) 14.12%,
    rgba(255, 231, 148, 0.6) 30.46%,
    rgba(255, 240, 185, 0.6) 42.84%,
    rgba(255, 229, 139, 0.6) 62.69%,
    rgba(252, 215, 83, 0.6) 82.48%,
    rgba(233, 188, 27, 0.6) 105.4%
  );
  border-radius: 5px;
  .mat-mdc-simple-snack-bar {
    color: var.$black !important;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    padding: 10px;
  }
  .mdc-snackbar__surface {
    background: transparent !important;
    box-shadow: none;
    .mat-mdc-snack-bar-label {
      color: var.$black;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    }
  }
  .mdc-button__label {
    background: var(--primary);
    padding: 5px 10px;
    color: var.$white;
    font-weight: 600;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  }
}

.body-prevent-scroll {
  overflow: hidden !important;
}

.mat-mdc-menu-panel.emoji-menu-list {
  min-width: auto !important;
}

.bulk-import-input-field,
.audit-log-input-field {
  height: 38px;

  .mat-mdc-select-arrow-wrapper {
    display: none;
  }
}

.manage-collapse-expand {
  color: var.$black;
}

.collapse-expand-icon {
  font-size: 24px;
}

.qr-code-modal {
  .modal-dialog {
    max-width: fit-content;
  }
}

.mat-mdc-dialog-container {
  margin: 2%;

  .mat-mdc-dialog-surface {
    border-radius: 20px !important;
  }
}

.revealAnswerCriteria-checkbox {
  .form-check-input {
    position: fixed;
    opacity: 0;
  }
  .custom-control-indicator {
    margin-top: -5px;
    width: 40px;
    height: 20px;
    background-color: transparent;
    border-radius: 3px;
    box-sizing: border-box;
  }
  .custom-control-description {
    font-size: 13px;
    width: auto;
    text-align: center;
    z-index: 9;
    padding: 0 8px;
  }
  .form-check-input:checked ~ .custom-control-indicator {
    background-color: var.$statusChipBlue;
    border-color: var.$statusChipBlue !important;
    background-image: none;
  }
  .form-check-input:checked ~ .custom-control-description {
    color: var.$white;
  }
  .form-check-input:focus ~ .custom-control-indicator {
    box-shadow: none;
  }
}

.ng-datatable-x .table tr.context-menu-row,
.drag-drop-backdrop {
  background-color: var.$azureishWhite !important;
}

.avatar-drop-layout {
  border-radius: 0 0 20px 20px;
}

.template-review-outer {
  .ng-datatable-x {
    .datatable {
      table {
        tbody {
          tr {
            .mdc-icon-button {
              .mdi-pencil,
              .mdi-eye,
              .mdi-delete {
                font-size: 16px;
                position: relative;
                bottom: 3px;
              }
            }
          }
        }
      }
    }
  }
}

.create-template {
  table {
    border-collapse: separate;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
  }
}

.asset-type-thumbnail {
  background-position: 0%;
  background-repeat: no-repeat;
  background-size: 50%;
  width: 30px;
  height: 30px;
  position: relative;
  right: 2px;
}

.drag-drop-asset-type-with-circle {
  position: absolute;
  top: -15px;
  width: 35px;
  height: 35px;
  border: 1px solid var.$veryLightGray;
  background: var.$white;
  left: -15px;
  right: 0;
  box-sizing: border-box;
  border-radius: 50% !important;
}

.drag-drop-asset-type-with-circle .mdi-image-broken-variant,
.asset-type-circle .mdi-image-broken-variant {
  color: var.$black;
  margin-left: -7px;
  font-size: 23px;
}

.drag-drop-layout-outer {
  background-color: var.$rapLMediumBlue;
  color: var.$white !important;
  box-shadow: 0 2px 6px 0 var.$rapLMediumBlue;
  font-size: 14px;
  border-radius: 5px;
  width: fit-content;
}

.drag-drop-zone-text {
  font-size: 14px;
  font-weight: 700;
  left: 0;
  position: fixed;
  right: 0;
  text-align: center;
  bottom: 25px;
  color: var.$charcoalGrey;
  z-index: 1000;

  span {
    font-size: 14px;
  }
}

.drag-drop-image-circle {
  background-image: url(var.$image-default-thumbnail);
}

.drag-drop-default-circle {
  background-image: url(var.$unknown-thumbnail);
}

.image-background-transparent {
  background-image: linear-gradient(45deg, var.$whisper 25%, transparent 25%),
    linear-gradient(-45deg, var.$whisper 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, var.$whisper 75%),
    linear-gradient(-45deg, transparent 75%, var.$whisper 75%);
  background-size: 20px 20px;
  background-position:
    0 0,
    0 10px,
    10px -10px,
    -10px 0px;
}

.mat-mdc-menu-panel {
  max-width: none !important;
  min-height: 48px !important;

  .mat-mdc-menu-content:not(:empty) {
    padding: 0 !important;
  }
}

.mat-mdc-slide-toggle .mdc-switch {
  background-color: var.$lightWhisper;
  border: 1px solid var.$lightRoyalBlue;
  border-radius: 8px;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before {
  background-color: var.$rapLMediumBlue !important;
}

.back-warning-dialog {
  .mat-mdc-dialog-container {
    padding: 0 !important;

    .mat-mdc-dialog-content {
      margin: 0 !important;
    }
  }
}

.chevron-left-circle {
  border-radius: 30px;
  background-color: var.$white;
  display: inline-block;
  color: var.$black;
  width: 28px;
  height: 28px;
  top: -15px;
  left: -4px;
  position: relative;
}

.admin-exp-side-bar-footer,
.monitor-exp-side-bar-footer,
.review-exp-side-bar-footer {
  .mdi-chevron-left:before {
    position: absolute;
    top: -4px;
    left: 2px;
  }
}

.markdown-viewer {
  p {
    white-space: pre-line;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.ask-rapl-markdown-viewer {
  th {
    padding: 8px;
    border-right: 1px solid #cdcdcd !important;
  }

  tr {
    padding: 8px;
    border: 1px solid #cdcdcd !important;
  }

  td {
    padding: 8px;
    border-right: 1px solid #cdcdcd !important;
  }

  pre {
    background-color: #000;
    padding: 20px;
    color: #fff;
  }

  p {
    margin-bottom: 0;
  }
}

.b-badge {
  font-size: 18px;
  height: 18px;
  width: 20px;
  display: inline-block;
  overflow: hidden;
  line-height: 17px;
  text-align: center;

  &.true {
    color: var.$kellyGreen;
  }

  &.false {
    color: var.$red;
  }
}

.badge-danger,
.bg-danger {
  color: var.$white !important;
  background-color: var.$red !important;
}

.badge-primary {
  color: var.$white !important;
  background-color: var.$rapLMediumBlue !important;
}

.badge-secondary {
  color: var.$white !important;
  background-color: var.$dimGray !important;
}

.badge-success,
.bg-success {
  color: var.$white !important;
  background-color: var.$kellyGreen !important;
}

.badge-warning,
.bg-warning {
  color: var.$white !important;
  background-color: var.$darkTangerineOrange !important;
}

.bg-transparent {
  background: url('assets/img/transparent.jpg');
}

.cdk-overlay-container {
  z-index: 99999;
}

.mobile-iframe {
  .background-img {
    background-image: none !important;
  }
}

.form-preview {
  .form-fiels-preview {
    .smartphone {
      .smartphone-bg {
        height: 765px !important;
        width: 360px !important;
        inset: 32px 0 0 35.5% !important;
      }
    }
    .desktop-bg {
      position: relative !important;
      inset: 23px 0 0 15% !important;
    }
  }
}

.quiz-question-preview,
.test-question-preview,
.survey-question-preview,
.form-fiels-preview {
  .smartphone {
    .smartphone-bg {
      background: url('assets/img/mobile-bg.png') no-repeat;
      height: 647px;
      position: absolute;
      top: 3px;
      left: 35.5%;
      right: 0;
      bottom: 0;
      background-size: contain;
    }

    .tab-bg {
      background: url('assets/img/ipad-bg.png') no-repeat;
      height: 647px;
      position: absolute;
      top: -5px;
      left: 28%;
      right: 0;
      bottom: 0;
      background-size: contain;
    }
  }

  .desktop-bg {
    background: url('assets/img/monitor.svg') no-repeat;
    height: 610px;
    position: absolute;
    top: 75px;
    left: 8%;
    right: 0;
    bottom: 0;
    background-size: contain;

    .desktop-quiz {
      width: 900px;
      position: relative;
      left: 33px;
      top: 18px;
      height: 540px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        height: 5px;
        width: 0;
      }

      &::-webkit-scrollbar-thumb {
        background: var.$veryLightGray;
        border-radius: 10px;
      }

      .choice-layout {
        overflow-x: hidden;
      }
    }
  }
}

.markdown-error {
  border-radius: 10px !important;
  border: 1px solid red !important;
}

body {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
  font-weight: 400;
  color: var.$charcoalGrey;
  padding-top: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  background: var.$blueWhite;

  .top-notification {
    transition: opacity 0.15s linear;

    &.confirm-box {
      position: fixed;
      display: flex;
      align-items: flex-end;
      top: 0;
      z-index: 100000;
      background: #0000006e;
      margin: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      justify-content: center;
      backdrop-filter: blur(1.5px);
    }

    padding: 0 15px;

    .media {
      box-shadow: 0 1px 3px 0 var.$dimGray;
      box-sizing: border-box;
      background: var.$white;
      width: 50%;
      margin: 0 auto;
      padding: 20px;
      min-width: 520px;
      border-radius: 2px;
      border-bottom: 4px solid var(--primary);
      margin-bottom: 80px;

      .w-48 {
        width: 48%;
      }
    }

    .warning-confirm {
      border-bottom: 4px solid red !important;
    }
  }

  .cdk-overlay-container {
    .cdk-overlay-pane {
      justify-content: center;

      .mat-mdc-option {
        .mdc-list-item__primary-text {
          font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
          font-size: 15px;
          letter-spacing: normal !important;
        }
      }

      .mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
        color: var.$rapLMediumBlue;
      }

      .mdc-menu-surface.mat-mdc-select-panel {
        padding: 0 !important;
      }
    }
  }
}

.blur-print {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

.body-cdk-backdrop {
  .cdk-overlay-pane {
    width: fit-content !important;
  }
}

.admin-background,
.monitor-background {
  background: var.$blueWhite !important;
}

.min-w-60 {
  min-width: 60% !important;
}

.btn {
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  cursor: pointer;

  &.btn-primary,
  &.btn-secondary,
  &.btn-primary.card-link,
  &.btn-danger,
  &.btn-danger-outline,
  &.btn-warning-outline {
    background-color: var.$rapLMediumBlue;
    border: 0;
    padding: 9px 8px;
    font-size: 12px;
    box-shadow: none !important;
    color: var.$white;
    text-transform: uppercase;

    .mdi.mdi-arrow-right {
      font-size: 16px;
      margin-left: 18px;
    }
  }

  &.btn-primary.btn-link,
  &.btn-secondary.btn-link {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    color: var.$rapLMediumBlue;
    background-color: var.$transparent !important;
  }

  &.btn-secondary.btn-link {
    background-color: var.$transparent;
    color: var.$dimGray !important;
  }

  &.btn-secondary.btn-link.btn-link-ripple:hover {
    background-color: var.$whisper !important;
  }

  &.btn-danger {
    background-color: var.$red;
  }

  &.btn-danger-outline {
    background-color: var.$white;
    color: var.$red;
    border: 1px solid var.$red;
  }

  &.btn-warning-outline {
    background-color: var.$white;
    color: var.$darkTangerineOrange;
    border: 1px solid var.$darkTangerineOrange;
  }
}

.btn-primary:disabled {
  cursor: not-allowed;
}

.h-100 {
  height: 100% !important;
}

.btn-ripple {
  background-position: center;
  transition: background 0.8s;
}

.dual-line-text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  word-break: break-all;
  white-space: normal !important;
}
.btn-ripple:hover {
  background: var.$rapLMediumBlue radial-gradient(circle, transparent 1%, var.$rapLMediumBlue 1%)
    center/15000%;
}

.btn-ripple:active {
  background-color: var.$veryLightGray;
  background-size: 100%;
  transition: background 0s;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
}

.custom-question-pagination {
  width: fit-content;
  min-width: 6%;
  max-width: 75% !important;
  margin: auto;
  border-radius: 0 0 20px 20px;

  .mat-mdc-tab-header {
    background: var.$white;
    border-bottom: none;
    border-radius: 0 0 20px 20px;
    border: 1px solid var.$veryLightGray;

    .mat-mdc-tab-labels {
      padding: 0 8px !important;
    }

    .mdc-tab {
      height: 38px;
      min-width: 36px;
      margin: 0px 10px;
      padding: 0;
      font-size: 12px;
      color: var.$white;
      border-radius: 50%;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    }

    .mdc-tab--active {
      .question-number {
        background: var.$rapLMediumBlue;
        color: var.$white !important;
        font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
      }
    }

    .mdc-tab__content {
      display: block;

      .mdc-tab__text-label {
        justify-content: center;
        margin: 5px 0;

        .question-number {
          height: 26px;
          min-width: 26px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          border-radius: 50%;
          color: var.$rapLMediumBlue;
          font-size: 12px;
          border: 1px solid var.$rapLMediumBlue;
          font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
        }
      }
    }
  }
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: transparent;
}

.btn-link-ripple {
  background-color: center;
}

.btn-link-ripple:hover {
  background-color: var.$aliceBlue !important;
}

.btn-link-ripple:active {
  background-color: var.$solitude !important;
  background-size: 100%;
}

.btn.navigate-to-sub-node {
  padding: 1px 6px;
  border-radius: 10% !important;
  width: auto !important;

  &.active {
    background-color: var.$solitude !important;
    color: var.$black !important;
  }
}

.instruction-files-preview {
  z-index: 999999 !important;
}

.load-more-lb {
  .load-more-btn {
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
    font-size: 14px !important;
  }
}

.btn.btn-icon-only {
  outline: 0;
  box-shadow: none;
  cursor: pointer;
  background: var.$transparent;
  font-size: 18px;
  height: 30px;
  border-radius: 50% !important;
  padding: 0px;
  margin-right: 10px;
  width: 30px;
  border-color: transparent !important;

  &:hover {
    background: var.$solitude !important;
    border-color: transparent !important;
  }

  &:active {
    background-color: var.$solitude !important;
  }

  &.btn-icon-lg {
    font-size: 18px;
  }
}

.homepage-certificate {
  width: 40px;
  height: 40px;
  position: absolute;
  top: -20px;
  border: solid 1px var(--primary);
  border-radius: 25px;
  right: 2%;
  background: var.$white;

  .certi-icon {
    height: 30px;
    width: auto;
    filter: unset !important;
  }

  .icon-outer {
    position: relative;
    top: 4px;
  }

  .grey-certi-icon {
    height: auto;
    width: 30px;
    filter: unset !important;
  }
}

.history-certificate {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 23px;
  border: solid 1px var(--primary);
  border-radius: 25px;
  right: 15px;
  background: white;

  .history-certi-icon {
    height: 28px;
    width: 28px;
    filter: unset !important;
  }

  .history-icon-outer {
    position: relative;
    top: 4px;
    left: 4px;
  }
}

.grey-certi-circle {
  border: solid 2px var.$silver;
}

.btn-link {
  font-size: 13px;
  color: var.$rapLMediumBlue;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.mat-mdc-input-element,
.mat-mdc-select-trigger,
.mat-mdc-checkbox {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
  font-size: 14px !important;
}

.mat-mdc-checkbox {
  .mdc-form-field {
    .mdc-label {
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    }
  }
}

.mat-mdc-select-trigger {
  height: 25px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: transparent !important;
}

.take-assignment {
  .mdc-text-field--filled {
    height: auto !important;
  }
}

.mdc-text-field {
  padding: 0 !important;
}

.mat-mdc-form-field {
  font-size: 14px;
  color: var.$dimGray;
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;

  mat-label {
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
    letter-spacing: normal !important;
  }
  .mat-mdc-form-field-hint-wrapper,
  .mat-mdc-form-field-error-wrapper {
    padding: 0;

    .mat-mdc-form-field-hint {
      font-size: 12px;
      color: var.$dimGray;
      margin-top: 5px;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
      letter-spacing: normal !important;
    }

    .mat-mdc-form-field-bottom-align::before {
      display: contents;
    }
  }
}

.mdc-label {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
}

.mat-calendar {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;

  .mdc-button__label {
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
  }
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var.$charcoalGrey;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var.$rapLMediumBlue;
}

.dialog-header-outer {
  position: sticky;
  top: 0;
  width: 576px;
  background: var.$white;
  border-radius: 6px 6px 0 0;
  padding: 1rem;
  border-bottom: 1px solid var.$veryLightGray;
  text-align: center;
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;

  .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 10px;

    &:hover {
      background-color: var.$whisper !important;
      border-radius: 50%;
    }
  }
}

.closing-date {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-icon-suffix {
        display: flex;
      }
    }
  }
}

.mat-mdc-checkbox-disabled {
  .mdc-form-field {
    .mdc-checkbox {
      .mdc-checkbox__background {
        background-color: var.$lightWhisper !important;
      }
    }
  }
}

.include-others-option {
  label {
    margin-top: 5px;
  }
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-datepicker-toggle {
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 30px;
    height: 30px;
    padding: 0;

    .mat-datepicker-toggle-default-icon {
      height: 18px;
    }
  }

  .mat-mdc-icon-button .mat-mdc-button-touch-target {
    width: 30px;
    height: 30px;
    padding: 0;
  }

  .mat-mdc-icon-button svg {
    vertical-align: middle;
  }
}

.mat-datepicker-content .mat-calendar {
  height: 380px !important;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-select-search-inner {
  .mat-select-search-clear {
    width: 40px !important;
    height: 40px !important;
    margin-top: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
  }
}

.quiz-question-choice-layout,
.answer-question-choice-layout {
  background-color: var.$white !important;
  width: 80%;
  margin: auto;
  border-radius: 20px !important;
  box-shadow: 0 1px 6px var.$veryLightGray !important;

  .quiz-text-choice {
    border-radius: 10px;
  }

  .card-img-top {
    border-radius: 0;
  }

  .answer-divider {
    width: 96%;
  }
}

.pre-login-layout {
  label {
    margin-bottom: 8px;
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      background: #fff;
      height: 46px;
      padding-left: 10px !important;
      border-radius: 5px;

      .mat-mdc-form-field-flex {
        height: 46px;
      }

      .mat-mdc-form-field-infix {
        padding-top: 10px;
        padding-bottom: 0px;
        padding-left: 5px;

        .mat-mdc-input-element {
          font-size: 18px !important;
          margin-top: 1px;
        }
      }

      .mat-mdc-form-field-hint {
        margin-bottom: 5px;

        .mat-mdc-form-field-bottom-align::before {
          display: contents;
        }
      }

      &:hover {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var.$veryLightGray !important;
        }
      }
    }

    .mat-mdc-form-field-hint-wrapper {
      position: relative;
      margin-top: 14px;

      .mat-mdc-form-field-hint {
        font-size: 14px;
        color: var.$dimGray;
      }
    }

    .mat-mdc-form-field-bottom-align::before {
      display: contents;
    }

    .mat-mdc-select {
      .mat-mdc-select-arrow {
        right: 12px;
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
      .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
      .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
      .mdc-notched-outline__trailing {
      border-color: var.$veryLightGray !important;
    }
  }

  .otp-form-field-outer {
    .mat-mdc-form-field-bottom-align::before {
      display: initial;
    }
  }

  .mat-form-field-invalid {
    .mat-mdc-text-field-wrapper {
      border-color: var(--warn);
      border: 1px solid var(--warn);

      .mat-mdc-input-element {
        color: var(--warn) !important;
      }

      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var.$transparent !important;
        }
      }
    }
  }
}

.bulk-import-select,
.audit-log-select {
  .mat-select-arrow {
    border: 0 !important;
  }

  .mat-mdc-select-trigger {
    bottom: 1px;
  }
}

.bundle-add-edit-form,
.node-add-edit-form {
  .mat-mdc-tab.mdc-tab {
    flex-grow: 0 !important;
    padding-left: 0;
    padding-right: 0;
  }
}

.card {
  box-shadow: 0 6px 10px var.$veryLightGray;
  border: 0;

  .card-header {
    background-color: var.$white;
    border: 0;
    color: var.$charcoalGrey;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 16px !important;
  }
}

.card > .list-group {
  border-top: none !important;
}

.card-outer-layout {
  box-shadow: none;
  border: 1px solid var.$veryLightGray;
}

.box-shadow-none {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.custom-control-label {
  &::before {
    width: 17px;
    height: 17px;
    border: 1px solid var.$dimGray;
    background-color: var.$white;
    box-sizing: border-box;
  }

  &::after {
    top: 6px;
    left: -22px;
    width: 13px;
    height: 13px;
    background-size: 100% 100%;
  }
}

.download-icon {
  font-size: 20px;
}

.image-expand-outer {
  position: relative;
  display: inline-block;
  border-radius: 50%;

  button {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 35px;
    color: var.$white;
    border-radius: 10px !important;

    .mdi-arrow-expand {
      font-size: 25px;
      color: var.$dimGray !important;
      padding: 4px 7px;
      border-radius: 50%;
      background: #0000001a;

      &:hover {
        color: var.$white !important;
        background: #00000069;
      }
    }
  }

  &:hover button {
    display: block;
  }
}
.dark-mode {
  .genie-answer {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var.$white !important;
    }
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var.$charcoalGrey;
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
}

h4 {
  font-size: 16px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var.$rapLMediumBlue;
  background-color: var.$rapLMediumBlue;
}

input[type='radio']:disabled:before {
  background-color: var.$whisper;
}

.modal-header {
  word-break: break-all;
  padding: 1rem !important;
  border-bottom: 1px solid var.$lightWhisper;

  .modal-title {
    word-break: break-word;
  }

  .btn-close {
    top: 3px;
    right: 3px;
    z-index: 999;
    outline: none;
    padding: 10px;

    img {
      position: relative;
      top: 0px;
      left: 0px;
    }

    &:hover {
      background-color: var.$whisper;
      color: #42526e;
      border-radius: 50%;
    }

    &:active {
      background-color: var.$veryLightGray;
      color: #42526e;
      border-radius: 50%;
    }
  }

  .close {
    position: relative;
    top: -18px;
    padding: 5px 10px 15px 10px;
    right: 10px;
    margin: -1rem -1rem -1rem auto;
    background: transparent;

    img {
      position: relative;
      top: 0px;
      left: 0px;
    }

    &:hover {
      background-color: var.$whisper;
      color: #42526e;
      border-radius: 50%;
    }

    &:active {
      background-color: var.$veryLightGray;
      color: #42526e;
      border-radius: 50%;
    }
  }
}

.custom-modal {
  padding: 42px 60px;

  .modal-header {
    border: 0;
    padding: 0 !important;

    .close {
      position: absolute;
      top: 18px;
      right: 18px;
      z-index: 999;
      outline: none;
      border-radius: 20px;
    }
  }

  .modal-subtitle {
    line-height: 1.5;
    color: var.$dimGray;
  }

  h4 {
    margin-bottom: 24px;
  }

  p {
    font-size: 12px;
    margin-bottom: 30px;
  }

  figure {
    margin-bottom: 5px;
  }

  button {
    cursor: pointer;
  }
}

.datatable {
  .details {
    padding: 15px !important;

    dt {
      font-size: 12px !important;
    }
  }

  .expand-row {
    .card-block {
      padding: 0px !important;
    }
    .details {
      padding: 25px !important;
    }
  }

  .table tfoot td .dt-footer ngb-pagination .pagination {
    .page-item.active .page-link {
      color: var.$rapLMediumBlue !important;
    }

    .page-item.disabled .page-link {
      color: var.$veryLightGray !important;
    }

    .page-item {
      a[aria-label='Previous'] {
        color: var.$rapLMediumBlue !important;
      }

      a[aria-label='First'] {
        color: var.$rapLMediumBlue !important;
      }

      a[aria-label='Next'] {
        color: var.$rapLMediumBlue !important;
      }

      a[aria-label='Last'] {
        color: var.$rapLMediumBlue !important;
      }
    }
  }

  .table tfoot td .dt-footer .show_total_count {
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
    font-size: 14px !important;
  }

  .table tr th {
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
  }

  .table tr td {
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
  }
}

.table-custom-checkbox {
  margin-top: 0 !important;
  padding-left: 24px;

  .custom-control-label {
    &::before {
      top: 18px !important;
      margin-top: -14px;
      width: 17px;
      height: 17px;
      border: 1px solid var.$dimGray;
      background-color: var.$white;
      border-radius: 0;
      box-sizing: border-box;
    }

    &::after {
      top: 6px !important;
    }
  }

  .custom-control-input {
    left: 2px;
    top: 6px;
  }
}

.deselect-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.modal-content {
  box-shadow: 0 1px 3px 0 var.$dimGray;
  border: 0;
  border-radius: 20px !important;
}

.exit-warning-modal {
  .modal-content {
    margin: auto;
    width: 80%;
  }
}

.form-control-feedback {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
  font-size: 14px !important;
}

.form-control,
.custom-select {
  &:focus {
    border-color: var.$veryLightGray;
    outline: 0;
    box-shadow: none;
  }
}

.group-button {
  .btn {
    border-radius: 40px;

    &.btn-primary {
      box-sizing: border-box;
      font-size: 12px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
      color: var.$dimGray !important;
      background-color: var.$white !important;
      border: solid 1px var.$veryLightGray !important;
      padding: 7px 16px;
      min-width: 150px;
    }

    &.btn-small {
      font-size: 12px;
      padding: 5px 20px;
    }

    &.active {
      color: var.$rapLMediumBlue !important;
    }

    &:last-child {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &.group-button-rect {
    .btn.btn-primary {
      border: 1px solid var.$veryLightGray !important;
      border-radius: 10px !important;
      margin-right: 10px;
      min-width: 0;
      padding: 0 10px !important;
    }
  }
}

.processing-processing-modal {
  .modal-content {
    background-color: transparent;
    box-shadow: none;
  }
}

.modal-close-btn {
  top: 0 !important;
}

.collaps-nav-btn-outer {
  border-bottom: 1px solid var.$lightWhisper !important;
}

.user-field-option {
  word-break: break-all;
  .mdc-evolution-chip__text-label {
    white-space: normal !important;
  }
}

.ng-datatable-x {
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: dashed;
    border: 0 solid;
  }

  .navbar-search {
    padding: 0.5rem 0.5rem !important;

    .mat-select-value,
    .search-by {
      font-size: 13px !important;
      height: calc(1.5em + 0.8rem + 7px) !important;
    }

    .mdc-text-field {
      padding: 0 6px !important;
    }

    .input-group-btn {
      height: 39px;

      .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea)
        .mat-mdc-form-field-infix {
        padding-top: 6px;
        padding-bottom: 6px;
      }

      .mat-mdc-form-field-infix {
        min-height: 35px;

        .mat-mdc-select-trigger {
          height: auto;
        }
      }

      .mdc-text-field--filled {
        height: 38px !important;
      }

      .mdc-line-ripple {
        display: none;
      }

      .mat-mdc-select {
        .mat-mdc-select-arrow {
          color: #0000008a;
        }
      }
    }
  }

  .table {
    tr.drag-drop-backdrop {
      background-color: var.$azureishWhite !important;
    }

    tr {
      th {
        font-size: 13px !important;
        color: var.$dimGray !important;
        text-transform: capitalize !important;
        font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;

        .sort-active-icon {
          color: var.$rapLMediumBlue !important;
        }
      }

      td {
        font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
        border-top: 1px solid var.$whisper !important;
        color: var.$black !important;
        font-size: 14px !important;

        .mat-mdc-icon-button,
        .btn-icon-md {
          width: 38px;
          height: 38px;
          padding: 0px;

          &:hover {
            color: var.$rapLMediumBlue !important;
          }
        }

        .mat-mdc-icon-button[disabled] {
          color: var.$veryLightGray !important;
        }
      }

      td.active {
        color: var.$black !important;
        font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
        font-weight: 700;
      }

      .no-row-heading {
        color: var.$dimGray !important;
        font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
        font-size: 20px;
      }

      .no-row-info {
        color: var.$dimGray !important;
        font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
        font-size: 16px !important;
      }

      .badge-counter {
        background: transparent !important;
        font-size: 13px !important;
        width: auto;
        min-width: 16px;
        height: 16px;
        padding: 2px;
        color: #404040 !important;
        font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;

        &:hover {
          background: var.$solitude !important;
        }

        &:active {
          background-color: var.$solitude !important;
        }
      }

      color: var.$black !important;
    }

    tbody {
      tr:nth-child(odd) {
        background-color: var.$whiteSmoke;

        td {
          background: transparent !important;
        }
      }

      tr {
        td.correct {
          color: var.$white !important;
          background: var.$pastelGreen !important;
        }
        &:hover {
          box-shadow: 0 2px 6px 0 var.$veryLightGray;
        }
      }

      .no-reults-found {
        background-color: transparent !important;

        &:hover {
          background: transparent !important;
          box-shadow: none;
        }

        td {
          border-radius: 20px;
        }
      }

      .drag-row {
        -webkit-transition-duration: 0.15s;
        transition-duration: 0.15s;
        -webkit-transition-property: opacity;
        transition-property: opacity;
        -webkit-transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
        transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
        opacity: 0.2;
      }
    }

    tfoot {
      .dt-footer ngb-pagination {
        a[aria-label='First'] {
          &::before {
            content: '\F04AE' !important;
          }
        }

        a[aria-label='Previous'] {
          &::before {
            content: '\F0141' !important;
          }
        }

        a[aria-label='Next'] {
          &::before {
            content: '\F0142' !important;
          }
        }

        a[aria-label='Last'] {
          &::before {
            content: '\F04AD' !important;
          }
        }
      }
    }
  }

  .d-flex {
    width: 100%;
  }

  .badge {
    font-size: 80%;
  }

  .valid-date {
    font-size: 100%;
  }

  .navbar-text {
    padding: 4px 0 0 0 !important;
  }
}

.table {
  tbody {
    .no-reults-found {
      filter: grayscale(1);

      &:hover {
        background: transparent !important;
        box-shadow: none;
      }

      img {
        width: 56px;
      }
    }
  }
}

.schedule-report-outer,
.download-report-outer {
  .report-label {
    font-size: 18px;
    color: var.$charcoalGrey;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  }

  .mat-drawer-content {
    overflow: hidden !important;
  }

  .bookmark-swipe-outer,
  .bookmark-outer {
    .mdc-tab-indicator {
      display: none !important;
    }

    .mat-mdc-tab-header {
      border: none !important;
    }

    .mat-mdc-tab-label-container {
      height: fit-content !important;
      display: flex !important;
      align-items: center !important;
      justify-content: start !important;
      padding: 25px 0px !important;

      .mat-mdc-tab-labels {
        align-items: center !important;
      }

      .mat-mdc-tab {
        flex-grow: 0 !important;
        height: fit-content !important;

        &:hover {
          background: transparent !important;
        }
      }

      .mat-mdc-tab-group,
      .mat-mdc-tab-nav-bar {
        --mat-tab-header-inactive-ripple-color: transparent !important;
      }
    }
  }
}

.admin-chip-menu-outer {
  .filter-menu-heading {
    border-bottom: 1px solid var.$lightWhisper;
    padding: 10px 15px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  .filter-menu-content-outer {
    padding: 10px 0px;
  }

  .form-group {
    display: block;
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .selected-list {
    background: #eaeaea99;
    border: 1px solid var.$lightWhisper;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    max-width: 300px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    padding: 8px 10px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100%;
    padding-top: 8px;
    margin-bottom: 0;

    &:hover {
      background: #eaeaea87;
    }
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 20px;
    width: 6px;
    height: 12px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .sort-label-outer {
    font-size: 14px;
  }
}

.form-filter-chip-outer {
  display: contents;

  .filter-reset-btn {
    font-size: 19px;
    color: var.$rapLMediumBlue;
    position: relative;
    top: 5.5px;
  }

  .filter-reset-btn-count {
    background: var.$rapLMediumBlue;
    color: var.$white;
    border-radius: 50%;
    font-size: 12px !important;
    position: relative;
    right: 8px;
    top: -4px;
    font-weight: 600;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
  }

  .chip-menu-btn {
    border: 1px solid var.$lightWhisper;
    border-radius: 20px;
    line-height: 28px;
    background: var.$white;
    margin-right: 18px;
    padding-right: 10px;
    padding-left: 10px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
  }

  .filter-search {
    width: 90%;
    margin: 10px auto;
    position: relative;
    border-radius: 20px;

    .form-control {
      border-radius: 20px;
    }
  }

  .dropdown-toggle:after {
    display: none;
  }

  .filter-menu-btn-active {
    background: var.$white;
    border: 1px solid var.$lightWhisper;
  }

  .filter-count {
    background: var.$veryLightGray;
    border-radius: 50%;
    color: var.$white;
    font-size: 11px;
    font-weight: 600;
    margin-right: 5px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 19px;
    text-align: center;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    max-width: max-content;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    padding: 8px 10px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 100%;
    padding-top: 8px;
    margin-bottom: 0;
    white-space: normal;
    display: block;
    word-wrap: break-word;
    &:hover {
      background: #eaeaea87;
    }
  }

  .filter-menu-content-outer {
    max-height: 400px;
    min-width: 425px;
    max-width: 500px;
  }

  .filter-menu-heading {
    font-size: 14px;
  }
}

h1 {
  margin-bottom: 10px;

  &.page-title {
    position: relative;
    font-size: 22px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    word-break: break-word !important;

    .mdi-chevron-left {
      float: left;
      border: 1px solid var.$whiteSmoke;
      border-radius: 50%;
      font-size: 24px;
      padding: 0px 4px 0px 2px;
    }

    .badge {
      font-size: 12px;
    }
  }
}

.mat-btn-group {
  border-radius: 20px !important;
  height: 32px;

  .mat-button-toggle-button {
    min-width: 150px;
    color: var.$dimGray;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 13px;
    text-transform: uppercase;

    .mat-button-toggle-label-content {
      line-height: 32px;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    }
  }

  .mat-button-toggle-checked {
    background-color: var.$azureishWhite !important;

    .mat-button-toggle-label-content {
      color: var.$rapLMediumBlue !important;
    }
  }
}

.learner-toggle-btn {
  .mat-button-toggle-button {
    .mat-button-toggle-label-content {
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
    }
  }
}

.body-cdk-backdrop {
  .cdk-overlay-pane {
    width: fit-content !important;
  }
}

.admin-filter-chip-outer {
  display: contents;

  .filter-reset-btn {
    font-size: 19px;
    color: var.$veryLightGray;
    position: relative;
    top: 5.5px;
  }

  .filter-reset-btn-count {
    background: var.$veryLightGray;
    color: var.$white;
    border-radius: 50%;
    font-size: 12px !important;
    position: relative;
    right: 8px;
    top: -4px;
    font-weight: 600;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
  }

  .chip-menu-btn {
    border: 1px solid var.$lightWhisper;
    border-radius: 20px;
    line-height: 28px;
    background: var.$white;
    margin-right: 18px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .filter-search {
    width: 90%;
    margin: 10px auto;
    position: relative;
    border-radius: 20px;

    .form-control {
      border-radius: 20px;
    }
  }

  .dropdown-toggle:after {
    display: none;
  }

  .filter-menu-btn-active {
    background: #eaeaea99;
    border: 1px solid var.$lightWhisper;
  }

  .filter-count {
    background: var.$veryLightGray;
    border-radius: 50%;
    color: var.$white;
    font-size: 11px;
    font-weight: 600;
    margin-right: 5px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 19px;
    text-align: center;
  }

  .dropdown-menu {
    transform: translateY(5px) !important;
    bottom: auto !important;
  }

  .dropdown-item:active {
    background-color: var.$lightWhisper;
    color: var.$black;
  }

  .filter-label {
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
  }
}

.has-danger {
  .col-form-label,
  .custom-control,
  .form-check-label,
  .form-control-feedback,
  .form-control-label {
    color: var.$red;
    font-size: 14px;
  }
}

.hide-tab-header {
  > .mat-mdc-tab-header {
    display: none;
  }
}

.highlight-search-text {
  color: var.$black;
  background: var.$azureishWhite;
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  font-size: 15px;
}

.card-body {
  padding: 1rem;
}

.required-label {
  color: var.$red;
  font-size: 14px;
}

.library-filter-menu-outer {
  .clear-libarry-search {
    right: 3px;
    height: 32px;
    padding: 4px 8px 5px 6px;
    top: 3px;
    position: absolute;
    background: var.$lightWhisper;
    border-radius: 0 20px 20px 0;
  }

  .filter-menu-heading {
    border-bottom: 1px solid var.$lightWhisper;
    padding: 10px 15px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  .form-group {
    display: block;

    &:hover {
      background: #eaeaea87;
    }
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    max-width: 300px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    padding: 8px 10px;
    font-size: 14px;
    overflow: hidden;
    min-width: 100%;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
  }

  .form-group input:not(:checked) + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 20px;
    width: 6px;
    height: 12px;
    border: solid #cdcdcda3;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 20px;
    width: 6px;
    height: 12px;
    border: solid var(--primary);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.library-filter-outer {
  .dropdown-item:active {
    background-color: var.$veryLightGray;
    border-radius: 5px;
  }

  .dropdown-item:hover {
    border-radius: 5px;
  }
}

.file-upload-box,
.quiz-modal,
.test-modal,
.lp-modal,
.poll-modal,
.survey-modal,
.create-schedule-panel,
.download-report,
.create-edit-feedback-panel,
.create-edit-bundle-subscription,
.create-edit-node-subscription,
.schedule-invite-template,
.assignment-create-edit-card,
.card-lb,
.create-review-year {
  .mdi-help-circle {
    cursor: pointer;
    align-self: center;
    z-index: 5;
    position: relative;
    font-size: 15px !important;
    color: #6a6a6a !important;
  }
}

.animate-bottom {
  position: relative;
  animation: animatebottom 0.7s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.mat-mcd-select {
  display: block !important;
  line-height: 2;
}

.icon-effect {
  padding: 5px 12px 12px 12px;
  position: relative;
  top: 6px;

  &:hover {
    background-color: var.$aliceBlue;
    color: #42526e;
    border-radius: 50%;
  }

  &:active {
    background-color: var.$solitude;
    color: #42526e;
    border-radius: 50%;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.event-custom-fields {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
  .show-hint {
    .mat-mdc-form-field-subscript-wrapper {
      display: inline-block !important;
    }
  }
}

.mat-form-field-invalid {
  .mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-hint-wrapper,
    .mat-mdc-form-field-error-wrapper {
      .mat-mdc-form-field-bottom-align::before {
        display: contents;
      }
    }
  }
}

.learner-dialog {
  .mat-mdc-dialog-container {
    padding: 0 !important;

    .mat-mdc-dialog-content {
      margin: 0 !important;
    }
  }
}

.file-detail-view {
  .mat-drawer-container {
    background-color: transparent !important;
  }
}

.default-snackbar {
  .mat-mdc-simple-snack-bar {
    justify-content: center;
    text-align: center;
  }
  .mdc-snackbar__surface {
    .mat-mdc-snack-bar-label {
      color: var.$white;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold;
    }
  }
}

.mat-dropdown-options {
  padding-left: 42px !important;
  .tick-icon {
    position: absolute;
    left: 13px;
    color: var.$rapLMediumBlue;
    font-size: 16px;
  }
}

.error-snackbar {
  .mdc-snackbar__surface {
    background-color: var.$red !important;
    border-radius: 50px !important;

    .mat-mdc-snack-bar-label {
      color: var.$white;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold;

      .mdc-button {
        color: var.$red !important;
        height: 25px;
        min-width: 20px;
        width: 25px;
        border-radius: 20px;
        font-weight: 700;
        background: var.$white;
      }
    }
  }
}

.success-snackbar {
  .mdc-snackbar__surface {
    background-color: #00c10f !important;
    border-radius: 50px !important;

    .mat-mdc-snack-bar-label {
      color: var.$white;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold;

      .mdc-button {
        color: #00c10f !important;
        height: 25px;
        min-width: 20px;
        width: 25px;
        border-radius: 20px;
        font-weight: 700;
        background: var.$white;
      }
    }
  }
}

.warning-snackbar {
  .mdc-snackbar__surface {
    background-color: var.$darkTangerineOrange !important;
    border-radius: 50px !important;

    .mat-mdc-snack-bar-label {
      color: var.$white;
      font-weight: 600;
      font-size: 16px;
      font-family: var.$font-workSans-semibold;

      .mdc-button {
        color: var.$darkTangerineOrange !important;
        height: 25px;
        min-width: 20px;
        width: 25px;
        border-radius: 20px;
        font-weight: 700;
        background: var.$white;
      }
    }
  }
}

.poll-question-title,
.poll-card-heading,
.poll-title-color {
  .markdown-viewer {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var.$white !important;
    }
  }
}

.history-search {
  .clear-libarry-search {
    position: relative;
    right: 33px;
    height: 32px;
    padding: 4px 8px 5px 6px;
    top: 3px;
    background: var.$lightWhisper;
    border-radius: 0 20px 20px 0;
  }

  .form-control {
    width: 400px;
    border-radius: 20px;
  }
}

.mobile-history-search {
  position: relative;

  .form-control {
    border-radius: 20px;
  }

  .clear-history-search {
    right: 3px;
    height: 32px;
    padding: 4px 8px 5px 6px;
    top: 3px;
    position: absolute;
    background: var.$lightWhisper;
    border-radius: 0 20px 20px 0;
  }
}

.history-poll-outer {
  .question-title {
    .poll-history-markdown,
    .survey-history-markdown {
      height: 60px;
      max-width: 365px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
      font-weight: 700;

      p {
        padding: 15px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        white-space: nowrap !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        padding: 15px;
      }
    }
  }
}

.poll-card-center {
  .markdown-text {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      white-space: pre !important;
      overflow: hidden !important;
      text-overflow: ellipsis !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 55px !important;
    }
  }
}

.override-snackbar-in-popup {
  top: 0 !important;
  left: 0 !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  min-height: 40px !important;

  .user-detail {
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 14px;

    .user-email {
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
      color: var.$charcoalGrey !important;

      small {
        font-size: 13px !important;
      }
    }
  }

  .mat-chip-remove {
    opacity: 0.9 !important;
  }
}

.mat-mdc-input-element {
  font-size: 14px !important;
  color: var.$charcoalGrey !important;
  line-height: 1 !important;
}

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  opacity: 0.5;
}

.admin-main,
.poll-modal,
.survey-modal,
.quiz-modal,
.test-modal,
.lp-modal,
.file-modal,
.update-usr-detail-outer,
.event-custom-fields,
.assignment-review-card-header,
.form-component-outer,
.event-host-create-modal,
.node-modal {
  .mat-mdc-input-element {
    line-height: 1.75 !important;
  }

  .mat-form-field-invalid {
    .mdc-floating-label {
      color: var.$red !important;
    }

    .mdc-text-field--filled .mdc-line-ripple:before {
      border-bottom-color: var.$red;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: var.$red !important;
    }

    .mdc-text-field--filled .mdc-line-ripple:after {
      border-bottom-color: var.$red;
    }
  }
}

.mat-ink-bar {
  background-color: var.$black !important;
}

.mat-expansion-panel-body {
  padding: 0 6px !important;
}

.mat-expansion-panel-header {
  padding-left: 10px !important;
}

.admin-side-bar-heading-outer,
.monitor-side-bar-panel {
  .mat-expansion-panel-header.mat-expanded {
    height: 48px;
    &:hover {
      background: transparent !important;
    }
  }
}

.history-mat-header {
  border: 1px solid var.$veryLightGray;
  border-radius: 0 0 20px 20px;

  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    background: var.$white;
    border-radius: 0 0 20px 20px;
  }
}

.survey-info-form,
.survey-make-live-form {
  .valid-time-input {
    margin-top: -13px !important;
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-infix {
      padding-bottom: 0;
    }

    .mat-mdc-form-field-flex {
      .mat-mdc-input-element {
        padding: 0 15px;
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
      border-color: var.$veryLightGray !important;
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
      .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
      .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
      .mdc-notched-outline__trailing {
      border-color: var.$dodgerBlue !important;
    }

    .mat-mdc-text-field-wrapper {
      &:hover {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: var.$veryLightGray !important;
        }
      }
    }
  }
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
  color: var.$black;
  font-size: 14px;
}

.count-slider {
  .mdc-slider__tick-mark--inactive,
  .mdc-slider__tick-mark--active {
    height: 4px !important;
    width: 4px !important;
  }
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header {
  border-bottom: 1px solid var.$veryLightGray;
}

.home-library-outer {
  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border-bottom: none;
  }

  .mat-mdc-tab-header-pagination-chevron {
    width: 20px;
    height: 20px;
    border-color: var(--primary);
    border-width: 3px 3px 0 0;
  }

  .mat-mdc-tab-header-pagination-disabled {
    .mat-mdc-tab-header-pagination-chevron {
      display: none;
    }
  }

  .mdc-tab-indicator {
    display: none;
  }
}

.mat-mdc-tab {
  .mat-mdc-tab-ripple {
    display: none;
  }

  &:hover {
    background: var.$white !important;
  }
}

.mat-mdc-menu-item {
  height: auto !important;
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
  font-size: 15px !important;
  text-align: left;

  .mat-mdc-menu-item-text {
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
    letter-spacing: normal !important;
  }

  .mat-icon {
    font-size: 26px;
    display: inline;
    color: var.$dimGray;
  }
}

.feedback-question-outer {
  .mat-mdc-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
  .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
    background-color: var.$rapLMediumBlue;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var.$rapLMediumBlue;
}

.mat-mdc-tab-link {
  color: var.$charcoalGrey;
}

.md-select-container {
  margin: 1em 0;
  padding-bottom: 6px;
}

.menu-custom-radio {
  .custom-control-indicator {
    box-sizing: border-box;
    background-color: var.$white;
    height: 18px;
  }
}

.mobie-logout-dialog,
.delete-account-dialog {
  .mat-mdc-dialog-container {
    padding: 0 !important;

    .mdc-dialog__title::before {
      height: auto;
    }

    .mat-mdc-dialog-content {
      margin: 0 !important;
    }
  }
}

.move-to-folder {
  .mat-mdc-dialog-container {
    position: relative;
    padding: 0;

    .mat-mdc-dialog-content {
      margin: 0 !important;
    }
  }
}

.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 610px;
  width: 90%;
}

.my-dialog-container-class {
  .mat-mdc-dialog-container {
    height: auto !important;
    padding: 0;
  }
}

.my-dialog-history-bookmark-container-class {
  .mat-mdc-dialog-container {
    padding: 0 !important;
    overflow-x: hidden;

    .mat-mdc-dialog-surface {
      box-shadow: none !important;
    }
  }
}

.mobile-update-modal-class {
  .mat-mdc-dialog-container {
    padding: 30px 0 20px 0 !important;
    overflow-x: hidden;
    background-color: var.$rapLMediumBlue;
  }
}

.web-session-timeout-modal {
  .mat-mdc-dialog-container {
    padding: 0 0 0 0 !important;
    overflow-x: hidden;
  }
}

.my-loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('assets/img/loader.gif') center no-repeat rgba(225, 225, 225, 0.12);
}

.svg-spinner-outer {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;

  .spinner-svg {
    width: 50px;
    height: 50px;
    margin: auto;
    top: 45%;
    position: relative;
  }
}

.mx-420 {
  max-height: 420px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    height: 5px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: var.$veryLightGray;
    border-radius: 10px;
  }
}

.nav-link {
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
}

.navbar {
  &.navbar-search {
    .input-group-btn {
      background-color: var.$aliceBlue;
      border: 1px solid var.$veryLightGray;
      border-right: none;
    }

    .input-group-text {
      background-color: var.$aliceBlue;
      border: 1px solid var.$veryLightGray;
    }

    .input-group-prepend {
      .input-group-text {
        border-right: none;
      }
    }

    .form-control {
      background-color: var.$aliceBlue;
      border: 1px solid var.$veryLightGray;

      &:focus,
      &:hover {
        box-shadow: none;
        background-color: var.$white;
      }
    }
  }

  &.navbar-header {
    .navbar-text {
      color: var.$charcoalGrey;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
      font-size: 16px;
    }
  }
}

.my-profile-outer {
  position: relative;
  top: 10px;
  left: 5px;
}

.otp-input-outer {
  border: solid 1px #cccccc !important;
  font-size: 18px !important;
  color: var.$charcoalGrey !important;
  width: 53px !important;
  height: 45px !important;
  background: #eff5ff;

  &:focus {
    border: 2px solid #1976d2 !important;
  }

  &:focus-visible {
    outline: var.$rapLMediumBlue;
  }
}

.copy-clipboard {
  font-size: 14px;
  color: var.$rapLMediumBlue;
  cursor: pointer;
}

.page-header {
  a {
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 12px;
    line-height: 1.33;
    color: var.$rapLMediumBlue !important;
    text-decoration: none !important;
    display: inline-block;
    position: relative;
    cursor: pointer;

    &.back {
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
      font-size: 20px;
    }
  }

  a:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -3px;
    visibility: hidden;
    background-color: var.$rapLMediumBlue;
    -webkit-transition: all 0.1s ease;
    transition: all 0.3s ease;
    -webkit-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
  }

  a:hover:after {
    visibility: visible;
    -webkit-transform: scaleX(1);
    -ms-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.pdf-viewer-outer {
  .mat-mdc-dialog-container {
    padding: 0;

    .mat-mdc-dialog-content {
      width: 100%;
      margin: auto;
      padding: inherit !important;
      max-height: 85vh;
    }
  }
}

.yt-viewer-outer {
  width: 100% !important;
  display: flex;
  justify-content: center;
  max-width: 100vw !important;

  .mat-mdc-dialog-container {
    overflow: initial;
    width: fit-content;
  }
}

.processing span {
  font-size: 35px;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.processing span:nth-child(2) {
  animation-delay: 0.2s;
}

.processing span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

.processing-modal {
  .modal-content {
    background-color: transparent;
    border: 0 !important;
    box-shadow: none !important;
  }
}

.selected-answer {
  &::before {
    background-color: var.$rapLMediumBlue !important;
  }

  &::after {
    background-image: url("data:image/svg+xml;charset=utf8,\A%3Csvg xmlns='http://www.w3.org/2000/svg'\AviewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
  }
}

.language-menu-btn {
  border: 1px solid var.$lightWhisper;
  border-radius: 20px;
  padding: 3px 6px 2px 12px;
  line-height: 28px;
  background: var.$white;

  .mdi-chevron-down {
    position: relative;
    top: 1px;
    font-size: 20px;
  }

  .language-label {
    margin-left: 6px;
    margin-right: 6px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    color: var.$black;
  }
}

.switch-view-dropdown {
  .form-group label {
    font-size: 15px !important;

    .mdi {
      font-size: 18px !important;
    }
  }

  .form-group {
    .switch-label {
      position: relative;
      cursor: pointer;
      max-width: 300px;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
      padding: 10px;
      font-size: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 100%;
      padding-top: 8px;
      margin-bottom: 0;

      &:hover {
        background: #eaeaea87;
      }
    }
  }
}

.report-issue-search-across-input {
  .mat-mdc-input-element {
    line-height: 2 !important;
  }
}

.move-folder-search {
  width: 50%;

  .mat-mdc-input-element {
    position: relative;
    top: -6px;
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      height: 45px;
      border: 1px solid var.$veryLightGray;
      padding-left: 15px !important;
      border-radius: 20px;
      width: 50%;
    }
  }
}

strong {
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
}

.tab-content-dt {
  width: 100%;
  min-height: 195px;

  &.loading-dt {
    background: url('assets/img/loading.svg') center no-repeat;

    tbody,
    form {
      opacity: 0.12;
    }
  }
}

.take-me-btn {
  color: var(--primary);
  --bs-btn-border-width: 0 !important;

  &:hover {
    color: var.$dimGray;
  }
}

.link-btn {
  &:hover {
    background: var.$aliceBlue !important;
  }

  &:active {
    background-color: var.$solitude !important;
  }
}

.template-monitor {
  margin-top: 50px;
  padding-right: 12px;
  background: var.$blueWhite;
  margin-right: 55px;
  margin-right: 52px;
  left: 52px;
  position: relative;

  &::-webkit-scrollbar {
    height: 5px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: var.$veryLightGray;
  }

  .breadcrumb-item + .breadcrumb-item {
    padding: 0em;
  }

  .btn-group {
    .active {
      background-color: var.$azureishWhite !important;
    }
  }

  .completion-users-layout {
    min-height: 93vh;

    .mat-mdc-progress-bar {
      .mdc-linear-progress__bar-inner {
        border-color: var.$rapLMediumBlue !important;
      }
    }
  }

  .monitor-lb,
  .monitor-usage {
    min-height: 93vh;
  }

  .breadcrumb-group-name {
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    color: var.$charcoalGrey;
    font-size: 14px;
    padding: 2px 8px;

    &:hover {
      color: var.$rapLMediumBlue;
      background-color: var.$azureishWhite;
      border-radius: 4px;
    }
  }

  .breadcrumb-active-item {
    color: var.$rapLMediumBlue;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  }

  .navbar.navbar-header .navbar-text {
    font-size: 15px;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    color: var.$dimGray;

    strong {
      color: #000;
      font-size: 14px;
    }
  }

  .mat-button-toggle-label-content {
    line-height: 32px;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
  }

  .nav {
    background-color: var.$white;
    position: fixed;
    top: 76px;
    right: 20px;
    left: 20px;
    box-shadow: 0 2px 4px 0 var.$veryLightGray;
    z-index: 100;

    .nav-link {
      text-transform: uppercase;
    }

    a {
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
      color: var.$charcoalGrey;
      text-decoration: none;
      font-size: 13px;
      height: 48px;
      padding: 0 24px;
      cursor: pointer;
      box-sizing: border-box;
      min-width: 160px;
      text-align: center;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap;
      position: relative;

      img {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        width: 16px;
        position: relative;
      }

      &::after {
        display: none;
      }
    }

    .nav-link.active {
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
      background-color: var.$white !important;
      color: var.$rapLMediumBlue !important;
      border-bottom: 2px solid var.$rapLMediumBlue;
      border-radius: 0;

      img {
        -webkit-filter: grayscale(0%);
        filter: grayscale(0%);
      }
    }

    .active a,
    a:hover {
      background-color: transparent;
    }

    a:hover {
      background-color: transparent;
    }

    .menu-list-group {
      min-width: 0;

      .list-group-item {
        border: 0;
        text-transform: uppercase;
        font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
        color: var.$charcoalGrey;
        white-space: nowrap;
        background-color: var.$white !important;

        &:first-child {
          border-bottom: 2px solid #e0e0e0 !important;
        }

        .link-img {
          filter: brightness(0.7);
        }
      }
    }

    .subMenu {
      .menu-list-group {
        display: none;
        background: var.$white;
        min-width: 90px;
        position: absolute;
        left: 0;
        border: 2px solid var.$whisper;
        border-top: 2px solid var.$rapLMediumBlue;
        top: 46px;

        .notify-list {
          max-height: 65vh;
        }
      }
    }

    .has-sub:hover {
      .menu-list-group {
        display: grid;
      }
    }
  }

  .aside {
    position: relative;
    right: 0;
    bottom: 0;
    overflow: auto;
    z-index: 9;
  }

  .breadcrumb {
    background-color: transparent;
    padding: 0;

    a {
      text-decoration: none;
    }

    .breadcrumb-item + .breadcrumb-item {
      text-decoration: none;

      &:before {
        content: '';
        padding: 0;
      }
    }

    .breadcrumb-item.arrow + .breadcrumb-item.arrow {
      text-decoration: none;

      &:before {
        content: '»';
        color: var.$charcoalGrey !important;
      }
    }

    .badge {
      margin-left: 10px;
    }
  }

  .btn-group,
  .group-button {
    .btn:hover {
      background-color: var.$aliceBlue !important;
    }

    .btn:active {
      background-color: var.$solitude !important;
    }

    .active:hover {
      background-color: var.$azureishWhite !important;
    }
  }

  .last-computed-at {
    position: relative;

    small {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 9;
    }
  }

  .card {
    box-shadow: none;
    border: 1px solid var.$veryLightGray;
    border-radius: 10px;

    .table th {
      padding: 1.5em 0.75em;
      position: relative;
    }

    .table th.border-left,
    td.border-left {
      border-left: 2px solid rgba(0, 0, 0, 0.12) !important;
    }

    .border-top {
      border-top: 2px solid rgba(0, 0, 0, 0.12) !important;
    }

    .table th.colspan-text {
      color: var.$rapLMediumBlue;
      font-size: 14px;
    }

    .table td.split-line {
      border-left: 1px solid #dee2e6;
    }

    .table td {
      font-size: 13px;
      font-weight: 600;

      &:hover {
        .cursor-pointer {
          color: var.$rapLMediumBlue;
        }
      }

      .row-icon {
        &::before {
          content: ' ';
          position: relative;
          display: inline-block;
          width: 14px;
          height: 13px;
          background-repeat: no-repeat;
          top: 3px;
        }
      }

      .points-icon {
        &::before {
          background-image: url(var.$points);
          height: 17px !important;
        }
      }

      .rank-icon {
        &::before {
          height: 12px !important;
          background-image: url(var.$crown);
        }
      }

      .trend-icon-up {
        &::before {
          background-image: url(var.$trend-up);
        }
      }

      .no-neutral-icon {
        &::before {
          background-image: url(var.$neutral);
        }
      }

      .trend-icon-down {
        &::before {
          background-image: url(var.$trend-down);
        }
      }
    }

    .text-red {
      color: var.$red;
    }

    .text-green {
      color: #00c10f;
    }

    .navbar-border-bottom {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }

    .breadcrumb {
      border-radius: 0;
    }

    .card-border {
      border: solid 1px rgba(0, 0, 0, 0.12);
    }

    h2 {
      font-size: 20px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
      line-height: 1.5;
      color: var.$rapLMediumBlue;
      text-transform: uppercase;

      small {
        font-size: 12px;
        vertical-align: middle;
        display: inline-block;
        padding: 0 0 6px 5px;
      }
    }

    h3 {
      font-size: 15px;
      color: var.$dimGray;
      position: relative;
      top: -3px;
      height: 38px;

      strong {
        color: #000;
        font-size: 14px;
      }
    }

    h5 {
      font-size: 14px;
      color: var.$charcoalGrey;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
      margin-bottom: 0;
      min-height: 20px;
      text-align: center;

      strong {
        color: #000;
      }
    }

    p {
      font-size: 15px;
    }

    .rating {
      position: relative;
      display: inline-block;
      width: 87px;
      height: 22px;
      vertical-align: middle;

      .rating-background,
      .rating-star {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 7;
        white-space: nowrap;
        overflow: hidden;
      }

      .rating-star {
        z-index: 9;
      }
    }
  }

  .mdi-arrow-left {
    padding: 2px 5px;
    font-size: 24px;
    top: 4px;
    height: 32px;
    width: 32px;
    display: inline-block;
    position: relative;

    &:hover {
      background: var.$whisper;
      border-radius: 50%;
    }
  }

  .mdi-file-export {
    &:hover {
      background: var.$whisper;
      border-radius: 50%;
      padding: 4px 6px;
    }
  }

  .badge-group {
    position: absolute;
    bottom: 3px;
    right: 10px;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.38);
    text-transform: none;

    .badge {
      margin-right: 5px;
      border-radius: 50%;
      width: 9px;
      height: 9px;
    }
  }

  .w-35 {
    width: 35% !important;
  }

  .text-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 500px;
  }

  .table-responsive {
    overflow: visible;
  }

  .monitor-stats {
    .card {
      height: 100px;

      h2 {
        font-size: 30px;
        margin-bottom: 5px;
      }
    }

    .card-body {
      padding: 15px 30px;
    }
  }

  .progress-stack {
    position: relative;

    .progress {
      position: static;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 1;
      float: left;
      margin-bottom: 10px;

      &.baseline {
        z-index: 4;
      }
    }
  }

  .info {
    position: relative;

    .mdi {
      padding: 0 0 0 5px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.38);
      display: inline-block;
    }

    .info-text {
      position: absolute;
      bottom: 25px;
      z-index: 9999;
      width: 200px;
      display: none;
      padding: 5px;
      background: rgba(0, 0, 0, 0.75);
      border-radius: 6px;
      left: -124px;
      color: var.$white;
      text-transform: none;
      white-space: normal;
      text-align: left;
      font-size: 12px;
      line-height: normal;
      box-sizing: border-box;

      &:before {
        box-sizing: border-box;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 5px 0 5px;
        border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
        bottom: -7px;
        left: 68%;
        position: absolute;
        margin-left: -5px;
      }
    }

    &:hover .info-text {
      display: block;
    }

    .info-text-w220 {
      width: 220px !important;

      &:before {
        left: 62%;
      }
    }

    .info-text-w200 {
      width: 200px !important;

      &:before {
        left: 68%;
      }
    }

    .info-text-custom {
      width: fit-content;
      left: -58px;
    }
  }

  .clickable {
    color: var.$rapLMediumBlue !important;
    font-weight: 600;
  }

  .ng-datatable-x .table tr th {
    color: var.$charcoalGrey !important;
  }

  .node-search-outer {
    margin: 0;

    .navbar-search {
      top: 0px;
      background: transparent;
      margin: -1px;
      width: 600px;
      padding: 10px 5px;
      z-index: 2;

      .input-group-append {
        position: absolute;
        right: 0px;
        top: 4px;
      }

      .input-group {
        box-shadow: 0 1px 6px var.$veryLightGray;
        background: white;
        border-radius: 20px;
        height: 45px;

        .search-by {
          border: none;
        }

        .input-group-text {
          border: none;
          margin: 0 3px 3px;

          .mdi-magnify {
            font-size: 20px;
          }
        }

        .magnify-input-group-text {
          border-radius: 20px 0 0 20px;
          padding: 4px 9px;
          margin: 4px;
        }

        .close-input-group-text {
          border-radius: 0 20px 20px 0 !important;
        }
      }

      .scroll-search-result {
        max-height: 385px;
        overflow-y: auto;
        overflow-x: hidden;
      }

      .node-search-result-outer {
        width: 87%;
        position: absolute;
        text-align: left;
        background: white;
        top: 46px;
        left: 45px;
        z-index: 2;
        box-shadow: 0 1px 6px var.$veryLightGray;

        .load-more-btn {
          border-top: 1px solid var.$veryLightGray;
          border-radius: 0 !important;
        }

        .node-search-result {
          width: 100%;

          tr {
            &:hover {
              background: var.$azureishWhite !important;
            }

            &:active {
              background-color: var.$solitude !important;
            }

            td {
              width: 100%;
              padding: 10px;

              .folder-icon {
                display: inline-block;

                .mdi {
                  font-size: 18px;
                }
              }

              .folder-ancestors-icon {
                position: relative;
                top: 10px;
              }

              h6 {
                font-size: 13px;
                width: 95%;
                font-family: var.$font-workSans-semibold, var.$font-helveticaneue,
                  var.$font-sans-serif;
                display: contents;
              }

              .ancestors {
                color: var.$dimGray;
                font-size: 14px;
                margin-left: 38px;
              }

              .created-at {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .across-nodes-overlap {
    position: fixed;
    width: 100%;
    background: #0000002e;
    height: 100%;
    z-index: 1;
    top: 0;
    right: 0;
  }

  .custom-across-nodes-overlap {
    position: fixed;
    left: 0;
  }

  .badge-indicator {
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 14px;

    .text {
      padding: 0.25rem;
      display: flex;
      align-items: center;
    }
  }
}

.progress {
  overflow: visible;
  margin-top: 11px;
  height: 4px;
  background-color: var.$veryLightGray !important;

  .progress-bar {
    white-space: nowrap;
    overflow: unset;

    &:first-child {
      border-radius: 12px !important;
    }

    &:last-child {
      border-radius: 12px !important;
    }

    span {
      position: relative;
      top: -15px;
      right: 0;
      color: rgba(0, 0, 0, 0.56);
      z-index: 999;
      font-size: 12px;
    }
  }

  &.justify-content-end {
    .progress-bar {
      &:first-child {
        border-radius: 0 0.25rem 0.25rem 0 !important;
      }
    }
  }
}

@media (max-width: 1000px) {
  .monitor-outer-layout {
    margin-left: 0px !important;
    padding: 0;
    margin-top: 0;
  }

  .monitor-manage-nav-list {
    display: none;
  }

  .mobile-container-fluid {
    padding-right: 0;
    padding-left: 0;
  }

  .template-monitor {
    margin-left: 0 !important;
    padding-right: 0;
    margin-right: 0;
    left: 0;

    &::-webkit-scrollbar {
      width: 0;
    }

    .aside {
      position: static;
    }

    .card {
      .table td {
        right: 40px;
      }
    }
  }
}

.monitor-user-filter {
  .filter-menu-btn {
    border: none !important;
    border-radius: 0 !important;
    padding: 0px 5px !important;
    font-size: 18px;
    line-height: 28px !important;
    background: var.$white;
    width: 100%;

    .mdi-chevron-down {
      position: relative;
      top: 1px;
    }

    .mdc-button__label {
      width: 100%;
    }

    .filter-label {
      margin-left: 6px;
      margin-right: 6px;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
      font-size: 16px;
    }

    .filter-btn {
      display: flex;
      justify-content: space-between;
      text-align: left;
    }

    .filter-count {
      background: var.$rapLMediumBlue;
      border-radius: 50%;
      color: var.$white;
      font-size: 12px;
      font-weight: 600;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
      display: inline-block;
      width: 18px;
      height: 18px;
      line-height: 18px;
    }
  }
}

.recurring-section {
  .mat-mdc-form-field-infix {
    width: fit-content !important;
    min-width: 18vh !important;
    .mat-mdc-select {
      width: fit-content !important;
    }
    .mat-mdc-select-arrow-wrapper {
      padding-left: 8px !important;
    }
  }
}

.monitor-filter-chip-outer {
  display: contents;

  .filter-reset-btn {
    font-size: 19px;
    color: var.$rapLMediumBlue;
    position: relative;
    top: 5.5px;
  }

  .filter-reset-btn-count {
    background: var.$rapLMediumBlue;
    color: var.$white;
    border-radius: 50%;
    font-size: 12px !important;
    position: relative;
    right: 8px;
    top: -4px;
    font-weight: 600;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
  }

  .chip-menu-btn {
    border: 1px solid var.$lightWhisper;
    border-radius: 20px;
    line-height: 28px;
    background: var.$white;
    margin-right: 18px;
    padding-right: 10px;
    padding-left: 10px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
  }

  .filter-search {
    width: 90%;
    margin: 10px auto;
    position: relative;
    border-radius: 20px;

    .form-control {
      border-radius: 20px;
    }
  }

  .dropdown-toggle:after {
    display: none;
  }

  .monitor-user-filter .-active {
    background: var.$white;
    border: 1px solid var.$lightWhisper;
  }

  .filter-count {
    background: var.$veryLightGray;
    border-radius: 50%;
    color: var.$white;
    font-size: 11px;
    font-weight: 600;
    margin-right: 5px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 19px;
    text-align: center;
  }
}

.user-activation-usgae-toggle {
  .mat-btn-group .mat-button-toggle-checked {
    background-color: var.$rapLMediumBlue !important;
  }
  .mat-btn-group .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: var.$white !important;
  }
}

.text-area-auto-expand {
  overflow: hidden !important;
  line-height: 1.5 !important;
  background-color: var.$white !important;
  resize: auto !important;
}

.status-danger {
  color: var.$red !important;
}

.text-primary {
  color: var.$rapLMediumBlue !important;
}

.text-red {
  color: var.$red;
}

.status-success {
  color: var.$kellyGreen !important;
}

.status-warning {
  color: var.$darkTangerineOrange !important;
}

.status-info {
  color: var.$statusChipBlue !important;
}

.toast-close-button {
  display: none;
}

.toaster-icon {
  left: 0.1em !important;
  top: 0.1em !important;
}

.toast-error {
  background-color: var.$red !important;
  opacity: 1 !important;
}

.toast-success {
  background-color: var.$kellyGreen !important;
  opacity: 1 !important;
}

.toast-warning {
  background-color: var.$darkTangerineOrange !important;
  opacity: 1 !important;
}

.topics-container {
  max-width: 100%;
  margin: 0 auto;
}

.upload-spinner {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0.5;
  background: var.$black;

  .uploading {
    width: 8%;
    position: absolute;
    margin: 0 auto;
    top: 40%;
    left: 0;
    right: 0;
    bottom: 0;

    .uploading-text {
      color: var.$white;

      div {
        display: inline;
        text-transform: uppercase;
      }
    }
  }
}

.user-custom-radio {
  .custom-control-indicator {
    box-sizing: border-box;
    background-color: var.$white;
    height: 18px;
  }

  .custom-control-description {
    padding-left: 12px;
  }

  .custom-control-input:checked ~ .custom-control-indicator {
    border: 0;
  }
}

.test-choice-text {
  .selected-choice {
    .custom-control-label {
      vertical-align: middle;
      color: var.$charcoalGrey;
      width: 12px;
      height: 12px;
      position: relative;
      left: 2px;
      border: 1px solid var.$white;
      border-radius: 50%;
      margin-right: 10px;
      z-index: 1;
    }
  }

  .list-group-item {
    .custom-control-input {
      left: 12px !important;
      opacity: 1 !important;
      z-index: 0 !important;
      accent-color: var.$charcoalGrey !important;
    }
  }
}

.pause-cancel-icon {
  filter: hue-rotate(120deg);
}

.header-mobile-back {
  font-size: 35px !important;
}

.test-info,
.poll-info-form,
.event-info-form,
.survey-info-form,
.quiz-info-form,
.event-custom-fields,
.file-upload-box,
.create-craft-form,
.custom-recurrence,
.create-edit-form-rule {
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    border-left: none !important;
  }
}

.update-usr-detail-outer {
  min-height: 94vh;
  display: flex;
  padding: 15px;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  .card {
    border-radius: 25px;
    padding: 20px;
    max-width: 400px !important;
    width: 100%;

    h1 {
      font-size: 20px !important;
    }

    .submit-btn {
      border-radius: 20px;
      float: right;
      width: 40%;
      font-size: 14px;
    }

    .mobile-submit-btn-outer {
      height: 60px;
      position: fixed;
      bottom: 0;
      width: 100%;
      padding-bottom: 10px;
      left: 0;
      background: var.$white;
      border-radius: 20px 20px 0 0;
      margin: auto;
      padding-top: 12px;
      text-align: center;

      .btn {
        width: 80%;
        margin: auto;
        border-radius: 20px;
        font-size: 14px;
      }
    }
  }
}

.change-pw-form {
  .mat-mdc-form-field {
    .mat-mdc-form-field-hint-wrapper {
      position: relative;
    }

    .mat-mdc-form-field-bottom-align::before {
      display: contents;
    }
  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var.$red !important;
  }
}

.user-email-verified {
  background-color: var.$lightGreen !important;
  border: 1px solid var.$kellyGreen;
}

.user-email-not-given {
  background-color: var.$lightRed !important;
  border: 1px solid var.$red;
}

.user-email-not-verified {
  background-color: var.$lightTangerineOrange !important;
  border: 1px solid var.$darkTangerineOrange;
}

.verify-user-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    overflow: visible;

    &::-webkit-scrollbar {
      height: 5px;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: var.$whisper;
      border-radius: 10px;
    }

    .mat-mdc-dialog-title {
      padding: 10px 15px 5px 15px;
      border-bottom: 1px solid var.$veryLightGray;
    }
  }
}

.otp-section {
  .next-btn {
    background-color: var(--primary) !important;
    color: #fff;
    padding: 10px 45px;
    border-radius: 20px;
    margin-top: 15px;
    width: 50%;
    margin-left: auto;
    font-family:
      WorkSans-SemiBold,
      Helvetica Neue,
      sans-serif;
    font-size: 14px;
  }

  .retry-otp,
  .try-new-email,
  .try-new-number {
    font-family:
      WorkSans-SemiBold,
      Helvetica Neue,
      sans-serif;
    font-size: 14px;
    color: var(--primary) !important;
  }

  .usr-subheader {
    strong {
      font-size: 14px !important;
    }
  }
}

.header-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-sidebar-tooltip {
  margin-left: 10px !important;
}

.admin-exp-side-bar,
.review-exp-side-bar {
  .admin-side-bar-heading-outer {
    .mat-expansion-indicator::after {
      color: var.$black !important;
    }
  }
}

.self-registration-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
    overflow: hidden;
  }
}

.instruction-expansion-header {
  .mat-content {
    flex: inherit !important;
  }

  .mat-expansion-indicator::after {
    margin-bottom: 6px !important;
  }
}

.warning-outer {
  font-size: 20px !important;
  position: relative;
  bottom: 25px;
}

.warning-btn {
  .proceed-btn {
    font-size: 14px;
  }
}

.word-break-break-all {
  word-break: break-all;
}

.word-break-break-word {
  word-break: break-word;
}

.audio-player {
  height: 50px !important;
}

.poll-q-preview {
  .modal-dialog {
    min-width: 1170px !important;
  }
}

.org-button {
  background: var(--primary) !important;
}

.todo-background,
.path-counter {
  .task-count,
  .path-count {
    border-radius: 18px;
    margin-left: 4px;
    font-size: 12px;
    display: inline-block;
    height: 20px;
    text-align: center;
    width: 35px;
    padding: 1px;
    position: relative;
  }
}

@media (min-width: 576px) {
  .path-outer,
  .quiz-outer,
  .event-outer,
  .assessment-outer,
  .template-outer {
    .mat-mdc-checkbox {
      label {
        display: inline !important;
        top: -1px;
        position: relative;
      }
    }
  }

  .card {
    box-shadow: 0 1px 6px var.$veryLightGray;
  }

  .otp-input-outer {
    font-size: 16px;
  }

  .pdf-viewer-outer {
    max-width: 90vw !important;
  }
}

@media (max-width: 576px) {
  .instruction-side-nav {
    .mat-drawer-inner-container {
      overflow-x: hidden !important;
      margin-top: 0 !important;
    }
  }

  .otp-input-outer {
    width: 40px !important;
    height: 40px !important;
  }

  .quiz-question-choice-layout,
  .answer-question-choice-layout {
    width: 100%;
    padding: 0;
    min-height: auto !important;
    box-shadow: none !important;

    .quiz-text-choice {
      border-radius: 10px;
      padding: 12px;
    }

    .card-img-top {
      border-radius: 0;
    }

    .choice-layout-outer {
      padding: 0 !important;
      margin-bottom: 20px;
      margin-top: 0 !important;
    }

    .answer-divider {
      width: 90%;
    }
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .error-snackbar {
    left: 20%;
  }

  .success-snackbar {
    left: 20%;
  }

  .warning-snackbar {
    left: 20%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
  .container {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }

  .card {
    box-shadow: 0 1px 6px var.$veryLightGray;
  }

  .custom-modal {
    padding: 30px 0;
  }

  .mat-mdc-dialog-content {
    padding: 0 4px !important;
  }

  .otp-input-outer {
    font-size: 16px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .pdf-viewer-outer {
    max-width: 90vw !important;
  }

  .exit-warning-modal {
    .modal-content {
      width: 100%;
    }
  }

  .home-library-outer {
    .library-container {
      .mat-mdc-tab-header-pagination {
        display: none;
      }
    }
  }
}

@media (max-width: 768px) {
  body {
    padding-top: 0 !important;
  }

  .card {
    box-shadow: 0 1px 6px var.$veryLightGray;
  }

  .page-header {
    .back {
      font-size: 16px !important;
    }
  }

  .image-expand-outer {
    button {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .page-title {
    font-size: 16px !important;
  }

  .instruction-side-nav {
    .mat-drawer-inner-container {
      overflow-x: hidden !important;
      margin-top: 0;
    }
  }

  .success-snackbar,
  .warning-snackbar,
  .error-snackbar {
    position: absolute !important;
    bottom: 5% !important;
    top: auto;
    max-width: 90% !important;
    box-shadow: none;
    background: transparent;
    width: fit-content !important;
    margin: auto !important;

    .mat-mdc-simple-snack-bar {
      font-weight: 600;
    }

    .mdc-snackbar__surface {
      background-color: var.$dimGray !important;
      border-radius: 7px !important;

      .mat-mdc-snack-bar-label {
        color: var.$white;
        text-align: center;
        padding: 5px;
        font-size: 12px;
        font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;

        .mat-mdc-snack-bar-actions {
          display: none;
        }
      }
    }
  }

  .library-filter-menu-outer {
    .form-group {
      display: block;

      &:hover {
        background: var.$white;
      }
    }

    .form-group label {
      font-size: 16px;
      max-width: 260px;
    }

    .filter-menu-content-outer {
      padding: 0;
    }
  }

  .instruction-description {
    p {
      margin-bottom: 7px !important;
      padding: 0px 12px !important;
    }
  }

  .upcoming-background {
    .empty-scenario-container {
      .title,
      .description {
        color: var.$white !important;
      }
    }
  }
}

.white-space-normal {
  white-space: normal;
}

@media (max-width: 380px) {
  body {
    padding-top: 0 !important;
  }

  .card {
    box-shadow: 0 1px 6px var.$veryLightGray;
  }

  .exit-warning-modal {
    .modal-content {
      width: 100%;
    }
  }

  .pdf-viewer-outer {
    max-width: 90vw !important;
  }
}

//Floating with shadow
:root {
  --icon-size: 1.8em;
  --speed: 4s;
  --easing: cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.floating-icon {
  font-size: 2rem;
  animation: float var(--speed) var(--easing) infinite;
  will-change: transform;
}

.floating-icon:before {
  content: ' ';
  position: absolute;
  bottom: calc(50% - 7rem);
  left: calc(50% - 2rem);
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
  transform: rotateX(95deg) translateY(0);
  animation: shadow var(--speed) var(--easing) infinite;
  will-change: transform;
}

@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-1rem);
  }

  100% {
    transform: translateY(0rem);
  }
}

@keyframes shadow {
  0% {
    transform: scaleX(1.75) rotateX(95deg) translateZ(0);
  }

  50% {
    transform: scaleX(0.5) rotateX(95deg) translateZ(-1rem);
  }

  100% {
    transform: scaleX(1.75) rotateX(95deg) translateZ(0);
  }
}

.confirm-ease-in-animation {
  transform: translate3d(0, 100%, 0);
  animation: ease-out-animation 0.3s ease;
}

@keyframes ease-in-animation {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.confirm-ease-out-animation {
  opacity: 0;
  animation: ease-in-animation 0.3s ease;
}

@keyframes ease-out-animation {
  0% {
    opacity: 1;
    transform: translateZ(0);
  }

  to {
    transform: translate3d(0, 100%, 0);
  }
}

.rapl-bold {
  font-weight: 600;
}

.rapl-blue-bold {
  font-weight: 600;
  color: var.$rapLMediumBlue;
}

.card-language {
  top: 11% !important;

  .language-header {
    font-size: 20px !important;
    font-weight: 600;
  }

  .language-radio-button {
    display: block;
    border-bottom: 1px solid var.$veryLightGray;
    margin: 0;
    padding: 5px;

    .mdc-label {
      line-height: 24px;
    }

    &:hover {
      background: var.$whisper;
    }

    &:active {
      background: var(--opaque);
    }

    .language-value {
      font-size: 14px;
      font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    }

    .language-text {
      color: var.$dimGray;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
      font-size: 12px;
    }
  }

  .mdc-form-field {
    width: 100%;
    cursor: pointer;

    label {
      width: 100%;
    }
  }

  .mat-mdc-radio-checked {
    background: var(--opaque);

    .mdc-radio__background {
      .mdc-radio__outer-circle {
        border-color: var(--primary) !important;
      }

      .mdc-radio__inner-circle {
        border-color: var(--primary) !important;
      }
    }
  }

  .lang-confirm-btn {
    width: 100%;
    margin-bottom: 10px;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }
}

.btn-icon-only {
  .mdi-delete,
  .mdi-fullscreen,
  .mdi-pencil,
  .mdi-eye,
  .mdi-image,
  .mdi-lightbulb-on-outline,
  .mdi-close,
  .mdi-table-row-remove,
  .mdi-clipboard-check,
  .mdi-account-remove,
  .mdi-account-plus,
  .mdi-arrow-left {
    background: transparent !important;
  }
}

.prevent-user-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.empty-scenario-container {
  height: 100vh;

  .title {
    font-size: 22px;
  }
  .description {
    font-size: 18px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
  }
}

.blur-effect {
  filter: blur(4px);
}

.cdk-overlay-backdrop,
.modal-open .modal {
  backdrop-filter: blur(5px);
}

.search-across-groups,
.search-across-issues,
.search-across-input,
.search-by {
  .mdc-line-ripple {
    display: none;
  }

  .mat-mdc-form-field {
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-input-element {
    position: relative;
    top: -5px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 22px;
  }

  .mat-form-field-hide-placeholder {
    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 22px;
    }
  }
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 25px;
}

.search-across-input {
  width: 89%;
}

.move-to-form-invalid {
  .mat-mdc-select {
    .mat-mdc-select-placeholder {
      color: var.$red;
    }

    .mat-mdc-select-arrow {
      color: var.$red;
    }
  }

  .mdc-line-ripple::before {
    border-bottom-color: var.$red !important;
  }
}

.empty-menu-backdrop {
  background-color: var.$azureishWhite !important;
}

#drag-ghost {
  background-color: var.$azureishWhite !important;
  color: var.$rapLMediumBlue;
  font-size: 14px;
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  opacity: 1 !important;
  padding: 5px;
  border-radius: 5px;
  border: 2px solid var.$rapLMediumBlue;
  width: 200px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  position: fixed;
}

.clone-drag {
  background: var.$dimGray !important;
  color: var.$white !important;
  font-size: 13px;
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
  padding: 5px;
  border-radius: 5px;
  width: 220px;
  border: 1px solid var.$charcoalGrey;
  z-index: 5000;
  opacity: 1 !important;
  position: absolute;
  left: -10000px;

  .node-name {
    max-width: 265px;
  }

  .badge-counter {
    font-size: 12px !important;
    color: var.$white;
    width: auto;
    min-width: 16px;
    height: 16px;
    padding: 2px;
  }
}

.global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

.s3-progress {
  .progress {
    .bg-success {
      background-color: var.$rapLMediumBlue !important;
    }
  }
}

.quiz-switch-lang {
  .cdk-overlay-pane {
    width: 38vh !important;
  }
}

.form-popup {
  .ng-datatable-x {
    .table tr {
      .badge-counter {
        color: var.$rapLMediumBlue !important;
      }
    }
  }

  .admin-filter-chip-outer {
    .dropdown-menu {
      left: -35px !important;
    }
  }
}

.filter-menu-outer {
  .clear-libarry-search {
    right: 3px;
    height: 32px;
    padding: 4px 8px 5px 6px;
    top: 3px;
    position: absolute;
    background: var.$lightWhisper;
    border-radius: 0 20px 20px 0;
  }

  .filter-menu-heading {
    border-bottom: 1px solid var.$lightWhisper;
    padding: 10px 15px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 16px;
    font-weight: 600;
  }

  .form-group {
    display: block;

    &:hover {
      background: #eaeaea87;
    }
  }

  .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-group label {
    position: relative;
    cursor: pointer;
    max-width: 300px;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    padding: 2px 10px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 100%;
    padding-top: 8px;
  }

  .form-group label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
  }

  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 11px;
    left: 20px;
    width: 6px;
    height: 12px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

.user-usage-layout {
  .form-inline {
    .btn-group-1 {
      display: flex;
    }

    .btn-icon-only {
      align-self: center;
    }
  }
}

.badge-not-using {
  background-color: #c52736;
  color: var.$white !important;
}

.badge-using {
  background-color: #00c10f;
  color: var.$white !important;
}

.bg-dormant {
  background-color: var.$rapLMediumBlue;
}

.bg-not-using {
  background-color: var.$red;
}

.bg-using {
  background-color: #00c10f;
}

.badge {
  padding: 0.25em 0.4em;
}

.badge-dormant {
  background-color: var.$rapLMediumBlue;
  color: var.$white !important;
}

.badge-no_trend {
  background-color: var.$suvaGrey;
  color: var.$white !important;
}

.badge-no-quiz {
  background-color: var.$veryLightGray;
  color: var.$white !important;
}

.engaged-days-badge {
  height: 17px;
  width: 17px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.details-outer {
  min-height: 0 !important;
}

.user-short-hand-name {
  font-size: 0;
}

.user-short-hand-name:first-letter {
  font-size: 18px;
}

.sentence-case {
  text-transform: lowercase !important;
}

.sentence-case::first-letter {
  text-transform: uppercase !important;
}

.user-profile-outer {
  border: 1px solid #eaeaea;
  border-radius: 50%;
  float: right;
  height: 40px;
  width: 40px;
  text-align: center;
  vertical-align: middle;
  background: var(--primary);
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  div {
    letter-spacing: 1px;
  }
}

.content-tag-options {
  .mat-pseudo-checkbox {
    display: none !important;
  }
}

.make-live-modal {
  .modal-dialog {
    max-width: 68% !important;
  }
}

.mobile-footer-menu {
  margin-bottom: 58px !important;
  .mat-bottom-sheet-container {
    box-shadow: none !important;
    min-height: fit-content !important;
    border-radius: 20px 20px 0 0;
    background: none !important;
    padding: 0 !important;
  }
}

.external-mobile-footer-menu {
  margin-bottom: 0 !important;
  .mat-bottom-sheet-container {
    box-shadow: none !important;
    min-height: fit-content !important;
    border-radius: 20px 20px 0 0;
    background: none !important;
    padding: 0 !important;
  }
}

.custom-cdk-backdrop {
  .cdk-overlay-dark-backdrop {
    bottom: 64px !important;
  }
}

.admin-main,
.event-custom-fields {
  .btn-outline-primary {
    color: var.$white !important;
    border: 1px solid var.$rapLMediumBlue;
  }

  #vertical-grid-scale {
    position: relative;
    min-width: 1125px;
    top: 0;
    left: 21px;
    background: url('assets/img/grid-vertical-scale.png') repeat;
    padding: 10px;
    border: 1px solid #404040;
  }

  #vertical-grid-line {
    position: absolute;
    width: 80%;
    min-height: 88vh;
    top: 0;
    left: 22px;
    background: url('assets/img/grid-vertical-line.png') repeat;
    padding: 10px;
    z-index: 1;
  }

  #horizontal-grid-scale {
    position: absolute;
    height: 93.5%;
    top: 20px;
    left: 0;
    background: url('assets/img/grid-horizontal-scale.png') repeat;
    padding: 10px;
    border: 1px solid #404040;
  }

  #horizontal-grid-line {
    position: absolute;
    width: 82%;
    min-height: 86vh;
    top: 21px;
    left: 0;
    background: url('assets/img/grid-horizontal-line.png') repeat;
    padding: 10px;
    z-index: 1;
  }

  .mat-mdc-form-field {
    font-size: 14px;
    color: var.$dimGray !important;
  }
}

.mat-expansion-panel-header,
.mat-expansion-panel-content {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
}

.quiz-points-info-expand-panel {
  .mat-expansion-panel-header {
    padding-right: 15px !important;
    padding-left: 0 !important;

    &:hover {
      background: transparent !important;
    }
  }
}

.mdc-tooltip {
  .mdc-tooltip__surface {
    background: var.$black;
    font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 15px;
  }
}

.nav-button-group {
  .btn-group {
    .active {
      background-color: var.$azureishWhite !important;
    }

    .btn:hover {
      background-color: var.$aliceBlue !important;
    }

    .btn:active {
      background-color: var.$solitude !important;
    }

    .active:hover {
      background-color: var.$azureishWhite !important;
    }

    .disabled {
      cursor: not-allowed;
    }
  }
}

.field-label {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
    opacity: 0.5;
  }
}

.qr-outer {
  .qr-img {
    width: fit-content !important;
    height: fit-content !important;
  }
}

.lang-selected {
  color: var.$rapLMediumBlue;
}

.animate__animated {
  visibility: visible !important;
}

.flex-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ngx-share {
  .share-icon {
    border: none;
    background: none;
  }
}

.sb-wrapper {
  width: 50px !important;
  height: 50px !important;
  min-width: 0 !important;
  border-radius: 50% !important;
}

.share-outer {
  padding: 5px !important;

  .modal-content {
    max-width: 355px !important;
    margin: auto;
  }

  .modal-body {
    padding: 5px;
  }

  .close {
    background: none;
    opacity: 1 !important;
    border: none;
    position: absolute;
    top: 15px;
    right: 20px;
  }

  .close:hover {
    background: none;
  }

  .sb-button {
    padding: 5px;
  }

  .sb-default {
    justify-content: center;
  }
}

.ongoing-dot {
  background: var.$darkTangerineOrange;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@media (min-width: 1400px) {
  .chevron-left-circle {
    left: 20px;
  }
}

.user-details-lb {
  .mat-mdc-tab-labels {
    justify-content: center;
  }
  .mat-mdc-tab-group {
    .mat-mdc-tab.mdc-tab {
      height: 100px;
      pointer-events: none;
      max-width: 100px;

      .mdc-tab-indicator {
        display: none;
      }
    }
  }
}

.drp-dwn-fltr {
  width: 300px !important;
}

.search-size {
  padding-right: 37px !important;
}

.rapl-semi-bold {
  font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif !important;
  font-weight: 700;
}

.rapl-regular {
  font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif !important;
}

.bg-info {
  color: var.$white !important;
  background-color: var.$statusChipBlue !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.prevent-click {
  pointer-events: none; /* Prevents click */
  opacity: 0.6; /* Optional: Visual indication */
}

//start Web and Mobile Base64 to svg
.take-test-outer,
.test-outer {
  .mdc-tab__text-label {
    border: 1px solid var.$whisper;
    border-radius: 50%;
    padding: 2px;
  }

  .mdc-tab-indicator__content--underline {
    border-color: var.$charcoalGrey !important;
  }
}

.profile-card-arrow {
  background: var(--primary);
  mask: url(./assets/img/profile-card-arrow.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/profile-card-arrow.svg) no-repeat center / contain;
}

.save-icon-svg {
  background: var(--primary) !important;
  mask: url(./assets/img/save-icon.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/save-icon.svg) no-repeat center / contain;
}

.learner-home {
  mask: url(./assets/img/learner-home.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-home.svg) no-repeat center / contain;
}

.learner-library {
  mask: url(./assets/img/learner-library.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-library.svg) no-repeat center / contain;
}

.learner-leaderboard {
  mask: url(./assets/img/learner-leaderboard.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-leaderboard.svg) no-repeat center / contain;
}

.learner-history {
  mask: url(./assets/img/history-outline.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/history-outline.svg) no-repeat center / contain;
}

.learner-insight {
  mask: url(./assets/img/learner-insight.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-insight.svg) no-repeat center / contain;
}

.learner-my-account {
  mask: url(./assets/img/learner-my-account.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-my-account.svg) no-repeat center / contain;
}

.learner-my-account-menu {
  mask: url(./assets/img/learner-my-account-menu.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-my-account-menu.svg) no-repeat center / contain;
}

.learner-help {
  mask: url(./assets/img/learner-help.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-help.svg) no-repeat center / contain;
}

.learner-ask-rapl {
  mask: url(./assets/img/chat-question-outline.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/chat-question-outline.svg) no-repeat center / contain;
}
.learner-forms {
  mask: url(./assets/img/forms-outline.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/forms-outline.svg) no-repeat center / contain;
}

.learner-events {
  mask: url(./assets/img/events-outline-icon.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/events-outline-icon.svg) no-repeat center / contain;
}

.learner-review {
  mask: url(./assets/img/learner-review.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/learner-review.svg) no-repeat center / contain;
}

.card-cerficate-icon {
  background: var(--primary);
  mask: url(./assets/img/my-certificate.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-certificate.svg) no-repeat center / contain;
}

.green-tick {
  background: var.$kellyGreen;
  mask: url(./assets/img/green-tick.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/green-tick.svg) no-repeat center / contain;
}

.history-quiz {
  background: var.$black;
  mask: url(./assets/img/history-quiz.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/history-quiz.svg) no-repeat center / contain;
}

.history-test {
  background: var.$black;
  mask: url(./assets/img/history-test.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/history-test.svg) no-repeat center / contain;
}

.history-library {
  background: var.$black;
  mask: url(./assets/img/history-library.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/history-library.svg) no-repeat center / contain;
}

.history-poll-survey {
  background: var.$black;
  mask: url(./assets/img/history-poll-survey.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/history-poll-survey.svg) no-repeat center / contain;
}

.history-assignment {
  background: var.$black;
  mask: url(./assets/img/history-assignment.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/history-assignment.svg) no-repeat center / contain;
}

.individual-lb {
  background: var.$black;
  mask: url(./assets/img/individual-lb.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/individual-lb.svg) no-repeat center / contain;
}

.team-lb {
  background: var.$black;
  mask: url(./assets/img/team-lb.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/team-lb.svg) no-repeat center / contain;
}

.my-profile-info {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-info.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-info.svg) no-repeat center / contain;
}

.my-group-info {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-group-info.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-group-info.svg) no-repeat center / contain;
}

.profile-field-info {
  background: var.$darkDimGray;
  mask: url(./assets/img/profile-field-info.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/profile-field-info.svg) no-repeat center / contain;
}

.my-profile-name {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-name.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-name.svg) no-repeat center / contain;
}

.my-profile-phone {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-phone.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-phone.svg) no-repeat center / contain;
}

.my-profile-mail {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-mail.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-mail.svg) no-repeat center / contain;
}

.my-profile-password {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-password.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-password.svg) no-repeat center / contain;
}

.my-profile-language {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-language.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-language.svg) no-repeat center / contain;
}

.my-profile-company {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-company.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-company.svg) no-repeat center / contain;
}

.my-profile-inactive-acc {
  background: var.$darkDimGray;
  mask: url(./assets/img/my-profile-inactive-acc.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/my-profile-inactive-acc.svg) no-repeat center / contain;
}

.feedback-user-dp {
  background: var(--primary);
  mask: url(./assets/img/feedback-user-dp.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/feedback-user-dp.svg) no-repeat center / contain;
}

.add-pause-learn {
  background: var(--primary);
  mask: url(./assets/img/add-pause-learn.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/add-pause-learn.svg) no-repeat center / contain;
}

.indiviual-form-icon {
  background: var.$darkDimGray;
  mask: url(./assets/img/individual-form-icon.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/individual-form-icon.svg) no-repeat center / contain;
}

.team-form-icon {
  background: var.$darkDimGray;
  mask: url(./assets/img/team-form-icon.svg) no-repeat center / contain;
  -webkit-mask: url(./assets/img/team-form-icon.svg) no-repeat center / contain;
}

.file-audio {
  background-image: url(var.$fileAudio);
}

.file-docx {
  background-image: url(var.$fileDocx);
}

.file-pdf {
  background-image: url(var.$filePdf);
}

.file-image {
  background-image: url(var.$fileImage);
}

.file-video {
  background-image: url(var.$fileVideo);
}

.add-svg-asset {
  background: var(--primary);
  height: 40px;
  margin-bottom: 4px;
  width: 40px;
}

.add-vaction {
  height: 28px;
  min-width: 28px;
}

.profile-info-user-icon {
  height: 24px;
  min-width: 24px;
}

.learner-icons {
  min-height: 24px !important;
  min-width: 24px !important;
  background-color: var.$slateGray;
}

.learner-mobile-icons {
  margin: 12% 10px;
  height: 25px;
}

.learning-path-outer,
.monitor-lb-freq {
  .mat-mdc-tab.mdc-tab--active {
    .mdc-tab__text-label {
      color: var(--primary) !important;
    }
  }
}

@media (min-width: 1024px) {
  .feed-page {
    .library-home-card {
      margin-left: -13px !important;
    }
  }
}

//end Web and Mobile Base64 to svg

@media (max-width: 768px) {
  .remark-box-outer {
    .mdc-line-ripple {
      display: none !important;
    }

    .mat-mdc-floating-label {
      left: -14px !important;
    }
  }

  .empty-scenario-container {
    .title {
      font-size: 20px !important;
    }
    .description {
      font-size: 16px !important;
    }
  }
}

.file-assign-btn-align {
  align-items: baseline !important;
}

.hide-profile-switch {
  .mat-mdc-tab-header {
    display: none !important;
  }
}

.selected-tab-icon {
  color: green;
}

.content-toggle-btn {
  .mat-button-toggle-disabled {
    cursor: not-allowed !important;

    .mat-button-toggle-button {
      opacity: 0.6 !important;
    }
  }
}

.network-retry {
  border: 1px solid var(--original) !important;
  padding: 5px !important;
}

.host-status-toggle {
  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle::before,
  .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
  .mdc-switch:enabled .mdc-switch__track::after {
    background: var.$kellyGreen !important;
  }

  .mdc-switch--selected,
  .mdc-switch--checked {
    &:hover {
      background-color: var.$lightWhisper !important;
    }
  }
}

.profile-container {
  padding-bottom: 90px !important;

  .profile-switch-bottom {
    align-items: center;

    .mdc-tab-indicator {
      display: none !important;
    }

    .mat-mdc-tab-header {
      padding: 3px;
      position: fixed !important;
      bottom: 25px !important;
      z-index: 3 !important;
      width: max-content !important;
      background: var.$whisper;
      border-radius: 28px;
      border: none !important;
    }

    .mdc-tab__text-label {
      color: var.$darkDimGray !important;

      .filled-icon {
        .profile-icon {
          background: var(--primary);
        }
      }
    }

    .mat-mdc-tab-body-content {
      overflow: hidden !important;
    }

    .mat-mdc-tab-label-container {
      border-radius: 28px;
    }

    .mat-mdc-tab-body-wrapper {
      width: 100% !important;
    }

    .mdc-tab--active {
      background-color: var.$white !important;
    }
  }

  .hide-tab-header {
    .mat-mdc-tab-body-wrapper {
      padding-top: 15px !important;
    }
  }

  .formlist-switch {
    .mat-mdc-tab-body-content {
      overflow: hidden;
    }
    .mat-mdc-tab-header {
      box-shadow: none;
      position: fixed;
      width: 800px;
      z-index: 10;
      background: var.$blueWhite;

      .mat-mdc-tab-labels {
        width: 30%;
      }
    }
    .mat-mdc-tab-body-wrapper {
      position: relative;
      padding-top: 50px;
    }

    .mat-mdc-tab-label-container {
      border-radius: 0;
      padding-top: 20px;
      .mdc-tab__text-label {
        color: var.$black;
        font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
        font-size: 16px;
      }
      .mdc-tab-indicator {
        display: flex;
        background: transparent;
        .mdc-tab-indicator__content {
          align-self: flex-end;
          border-color: var(--primary);
          border-top-width: 4px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
        }
      }
    }
    .mdc-tab--active {
      .mdc-tab__text-label {
        color: var(--primary) !important;
      }
    }
  }
}

@media (max-width: 992px) {
  .formlist-switch {
    .mat-mdc-tab-header {
      width: 100% !important;
      background: var.$white !important;
      .mat-mdc-tab-labels {
        width: 100% !important;
      }
      .mat-mdc-tab:hover {
        background: var.$white !important;
      }
    }
    .mat-mdc-tab-label-container {
      padding-top: 0 !important;
    }
  }
}

.great-msg-poopers {
  background-image: url('assets/img/poopers.svg') !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: contain;
}

.mobile-lang-list {
  .mdc-form-field {
    min-width: 100% !important;

    .mdc-label {
      min-width: 100% !important;
    }
  }

  .mdc-radio {
    display: none !important;
  }
}

.clone-checkbox-label {
  .mdc-label {
    padding-left: 0px !important;
  }
}

.quiz-lang-checkbox {
  background-color: transparent !important;

  &:hover {
    background-color: var.$lightWhisper !important;
  }

  .mat-pseudo-checkbox {
    display: none !important;
  }

  .mdc-list-item__primary-text {
    color: inherit !important;
  }
}

.mat-button-toggle-group {
  .mat-button-toggle {
    .mat-pseudo-checkbox {
      display: none;
    }
  }
}

.insight-drop-menu {
  .library-filter-menu-outer {
    min-width: 157px;

    .sort-label-active {
      color: var(--primary) !important;
    }

    .sort-label-outer {
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;

      &:hover {
        background-color: var.$lightWhisper !important;
      }
    }

    .selected-check {
      color: var(--primary) !important;
    }
  }
}

.make-live-btn {
  color: var.$green !important;
  border: 1.8px double transparent !important;
  background-image: linear-gradient(rgb(255 255 255), rgb(255 255 255)),
    radial-gradient(circle at left top, rgb(133 218 1), rgb(0 217 137));
  background-origin: border-box;
  background-clip: padding-box, border-box !important;
  .mdi {
    color: var.$green !important;
  }

  &:hover {
    color: var.$white !important;
    background-image: linear-gradient(0deg, rgb(30, 142, 62), rgb(30, 142, 62)),
      radial-gradient(circle at left top, rgb(30, 142, 62), rgb(30, 142, 62)) !important;
    border: none;
    box-shadow: 0px 1.2px 11px -2px #14a73ec4;
    .mdi {
      color: var.$white !important;
    }
  }
}

.test-info-inputs,
.poll-info-form,
.event-info-form,
.event-custom-fields,
.quiz-info-form,
.create-craft-form,
.custom-recurrence,
.admin-file-info-form {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: var.$veryLightGray !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: var.$dodgerBlue !important;
  }
}

.event-custom-fields-required,
.timezone-invalid,
.custom-recurrence-required,
.tag-required {
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: red !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused
    .mdc-notched-outline__trailing {
    border-color: red !important;
  }
  .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    color: red !important;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var.$red !important;
  }
  .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    border-left: 0 !important;
  }
}

.dark-bottom-sheet {
  .mat-bottom-sheet-container {
    background-color: var.$charcoalGrey !important;
    border-radius: 20px 20px 0 0 !important;

    .genie-menu-label {
      color: var.$white !important;
    }

    .genie-menu-img,
    .btn-close {
      filter: invert(1);
    }
  }
}

.custom-recurring-input {
  .mdc-line-ripple {
    display: none !important;
  }

  .date-input-outer {
    min-width: 15vh !important;
    background: #e6e6e6;
    border-radius: 11px;
    height: 38px;
    .mat-mdc-form-field-infix {
      padding: 0 !important;
      margin-top: 8px !important;
      padding-top: 0px !important;
    }
    .mat-mdc-form-field-icon-suffix {
      position: absolute !important;
      right: 3px !important;
      top: 4px !important;
    }
  }

  .input-outer {
    width: 52px;
    background: #e6e6e6;
    border-radius: 11px;
    height: 38px;

    .mat-mdc-form-field-infix {
      padding: 0 !important;
      height: 20px;
      margin-top: 6px;
      padding-left: 8px !important;
      padding-top: 0px !important;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.light-bottom-sheet {
  .mat-bottom-sheet-container {
    background-color: var.$white !important;
    border-radius: 20px 20px 0 0 !important;

    .genie-menu-label {
      color: var.$black !important;
    }
  }
}

.dropdown-field {
  .mat-mdc-form-field-icon-suffix {
    display: flex !important;
    align-items: center !important;
  }
}
.forms-filter-bottom-sheet {
  .mat-bottom-sheet-container {
    height: 100vh;
    max-height: 100vh !important;
  }
}

.language-default-toggle {
  label {
    width: max-content !important;
  }
}

.custom-field-empty {
  .mdc-list-item__primary-text {
    margin: 0 !important;
  }
}

.form-card {
  .mat-accordion {
    padding: 0px !important;

    .mat-expansion-panel-header {
      align-items: start;
      padding: 0 !important;
      height: fit-content !important;
    }

    .mat-expansion-indicator {
      width: 15px !important;
      margin-right: 5px;
    }

    .mat-expansion-panel {
      background: transparent !important;
      box-shadow: none !important;
      padding-top: 2px;
    }

    .mat-expansion-panel-header-title {
      margin-right: 10px !important;
    }

    .mat-expansion-panel:not(.mat-expanded)
      .mat-expansion-panel-header:not([aria-disabled='true']):hover {
      background: var.$white !important;
    }
  }
}

.forms-btn-group {
  border-radius: 20px !important;

  .mat-button-toggle-button {
    min-width: 180px;
    color: var.$dimGray;
    font-family: var.$font-workSans-semibold, var.$font-helveticaneue, var.$font-sans-serif;
    font-size: 16px;
    text-transform: none !important;

    .mat-button-toggle-label-content {
      line-height: 32px;
      font-family: var.$font-workSans-regular, var.$font-helveticaneue, var.$font-sans-serif;
      color: var.$blueWhale;
      text-transform: none !important;
    }
  }

  .mat-button-toggle-checked {
    background-color: var.$azureishWhite !important;

    .mat-button-toggle-label-content {
      color: var(--primary) !important;
    }
  }
}

.forms-drawer-outer {
  overflow: inherit !important;

  .mat-drawer-content {
    overflow: inherit !important;
  }
}

.admin-section-tab,
.form-section-tab {
  .mat-mdc-tab-label-container {
    .mat-mdc-tab-labels {
      .mdc-tab {
        padding: 0 !important;
      }
    }
  }
}

.across-nodes-overlap-tab {
  position: absolute;
  width: 100%;
  background: #0000002e;
  height: 100%;
  z-index: 1;
  top: 0;
  margin-left: -24px;
  border-radius: 0 0 20px 20px;
}

.full-screen-modal {
  .modal-dialog {
    max-width: 100vh !important;
    margin: 20px auto !important;
  }
}

.rapl-blue {
  color: var.$rapLMediumBlue !important;
}

.learner-bg {
  background-color: var.$blueWhite;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 0;
}

.event-custom-field-modal {
  .modal-dialog {
    min-width: 72%;
    max-width: 85%;
    width: auto !important;
  }
}

.language-modal-outer {
  padding: 0 !important;

  .submit-pwd-btn {
    border-radius: 20px;
    font-size: 14px;
    margin-left: auto;
  }

  .change-password {
    .title {
      font-size: 20px;
    }
  }
}

.field-dropdown-chip {
  .mat-mdc-chip.mat-mdc-standard-chip {
    min-height: 40px !important;
    max-height: fit-content !important;
  }

  .mdc-evolution-chip__text-label {
    overflow: visible !important;
    white-space: normal !important;
  }
}

.profile-options {
  .profile-edit-modal {
    .submit-btn {
      border-radius: 20px;
      float: right;
      width: 40%;
      font-size: 14px;
    }

    .name-edit-layout {
      .header-text {
        font-size: 22px;
      }

      .custom-modal {
        padding: 25px;
      }

      mat-label {
        font-size: 16px;
      }

      input {
        font-size: 20px;
      }
    }
  }
}
